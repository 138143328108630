<template>
    <svg :copyright="this.$root.Copyright" :style="custom" @mouseleave.stop="leave" @mouseenter.stop="hover"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="16px" height="16px">
        <path fill-rule="evenodd"  :fill="fill"
              d="M-0.000,0.900 C0.032,0.673 0.138,0.462 0.300,0.300 C0.462,0.138 0.673,0.032 0.900,0.000 C5.633,0.000 10.367,0.000 15.100,0.000 C15.366,-0.011 15.626,0.095 15.800,0.300 C15.942,0.467 16.011,0.682 16.000,0.900 C16.000,5.633 16.000,10.367 16.000,15.100 C16.011,15.366 15.905,15.626 15.700,15.800 C15.533,15.942 15.318,16.011 15.100,16.000 C10.367,16.000 5.633,16.000 0.900,16.000 C0.682,16.011 0.467,15.942 0.300,15.800 C0.095,15.626 -0.011,15.366 -0.000,15.100 C-0.000,10.367 -0.000,5.633 -0.000,0.900 Z"/>
        <path fill-rule="evenodd"  fill="rgb(44, 47, 55)"
              d="M5.100,5.500 L10.900,5.500 C11.009,5.501 11.114,5.535 11.200,5.600 C11.326,5.695 11.395,5.844 11.400,6.000 C10.367,7.467 9.333,8.933 8.300,10.400 C8.250,10.521 8.131,10.600 8.000,10.600 C7.869,10.600 7.750,10.521 7.700,10.400 C6.667,8.933 5.633,7.467 4.600,6.000 C4.601,5.891 4.635,5.786 4.700,5.700 C4.795,5.574 4.944,5.505 5.100,5.500 Z"/>
    </svg>
</template>

<script>
    export default {
        name: "dropdownButton",
        props:{
            custom:{
                type:String,
                default:null,
                required:false,
            }
        },
        data(){
            return {
                fill:'rgb(234, 234, 234)'
            }
        },
        methods:{
            hover(){
                this.fill='rgb(210, 210, 210)';
            },
            leave(){
                this.fill='rgb(234, 234, 234)';
            }
        }
    }
</script>

<style scoped>

</style>
