<template>
    <div class="BananaCardItemA" :style="BananaCardItemStyleA">
        <div class="cardItemA">
            <div class="left">
                <img :src="url" alt="" class="headImg" :style="delay"/>
            </div>
            <div class="right" @mousemove.stop="hoverDetails($event)" @mouseleave.stop="view=false">
                <div class="row0">
                    <span class="anonymousA" v-if="anonymous==='T'">匿名</span>
                    <span class="userQqA" v-if="anonymous==='F'">QQ</span>
                    <span class="gamgeNameA">{{game_name}}</span>
                </div>
                <div class="row1">
                    {{nickname}}
                </div>
                <div class="row2">
                    QQ:{{qq_id}}&nbsp;UID:{{game_id}}
                </div>
                <div class="row2">
                    备注:{{remarks}}
                </div>
            </div>
        </div>
    </div>
    <div class="hoverDetailsA" :style="hoverDetailsStyleA" v-show="view">
        <div class="row8">
            游戏名称：{{game_name}}
        </div>
        <div class="row8">
            游戏昵称：{{nickname}}
        </div>
        <div class="row8">
            ＱＱ账号：{{qq_id}}
        </div>
        <div class="row8">
            游戏账号：{{game_id}}
        </div>
        <div class="row8">
            备注信息：{{remarks}}
        </div>
        <div class="row8" v-for="(item,index) in custom" :key="index">
            {{item.key}}：{{item.value}}
        </div>
    </div>
</template>

<script>
export default {
    name: "BananaCardItemPreview",
    props:{
        width:{
            type:Number,
            default:320,
            required:false
        },
        anonymous:{
          type:String,
          default:'F',
          required:false,
        },
        qq_id:{
            type:String,
            default:'none',
            required:false
        },
        game_id:{
            type:String,
            default:'none',
            required:false
        },
        nickname:{
            type:String,
            default:'none',
            required:false
        },
        game_name:{
            type:String,
            default:'none',
            required:false
        },
        remarks:{
            type:String,
            default:'none',
            required:false
        },
        custom:{//自定义的数据（键值对）
            type:Array,
            default:function () {
                return []
            },
            required:false
        }
    },
    data(){
        return{
            left: 0,
            top:0,
            view:false
        }
    },
    computed:{
        url(){
            return `http://q.qlogo.cn/g?b=qq&nk=${this.qq_id}&s=640&mType=friendlist`;
        },
        delay(){
            return `animation-delay: ${this.serial_number*0.02}s;`;
        },
        hoverDetailsStyleA(){
            return {
                transform:`translate(${this.left}px, ${this.top}px)`
            }
        },
        BananaCardItemStyleA(){
            return{
                width: this.width+'px',
                animationDelay: `${this.serial_number*0.1}s`,
            }
        },
    },
    methods:{
        hoverDetails(ev){//鼠标悬浮显示详细信息
            this.left=ev.x+10;
            this.top=ev.y+10;
            if(this.view===false)this.view=true;
        }
    }
}
</script>

<style scoped>
/*AB css start*/
/*A横屏*/
.row0{
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    position: absolute;
    left: 0px;
    top: -20px;
    overflow: hidden;
}
.anonymousA{
    width: 40px;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #ff6f70;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
}
.userQqA{
    cursor: default;
    width: 30px;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #00c229;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
}
.gamgeNameA{
    cursor: default;
    width: auto;
    max-width: 200px;
    overflow-x: hidden;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #189eff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
    padding: 0px 4px;
}
.hoverDetailsA{
    width: calc(225px - 20px);
    height: auto;
    padding: 10px;
    background: rgba(250,250,255,0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    position: fixed;
    pointer-events: none;
    z-index: 60;
    border-radius: 10px;
    color: #1b1b1b;
    left: 0;
    top: 0;
}
.BananaCardItemA{
    margin-top: 20px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: BananaCardItemAnimationA 0.3s forwards;
    opacity: 0;
}
@keyframes BananaCardItemAnimationA {
    0%{
        opacity: 0;
        transform: scale(2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.cardItemA{
    width: calc(320px - 10px);
    height: calc(80px - 10px);
    padding: 5px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/*AB css end*/
/*通用css start*/
.left{
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.right{
    cursor: pointer;
    width: calc(100% - 60px - 5px);
    padding-left: 5px;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.headImg{
    width: 90%;
    height: auto;
    border-radius: 5px;
    animation:headImgAnimation 0.5s forwards;
}
@keyframes headImgAnimation{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
.row1{
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    overflow: hidden;
}
.row2{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.row8{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
}
/*通用css end*/
</style>