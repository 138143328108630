<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="96px" height="96px">
    <path fill-rule="evenodd"  fill="rgb(236, 48, 39)"
          d="M48.000,0.000 C74.510,0.000 96.000,21.490 96.000,48.000 C96.000,74.510 74.510,96.000 48.000,96.000 C21.490,96.000 0.000,74.510 0.000,48.000 C0.000,21.490 21.490,0.000 48.000,0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M19.200,67.200 C16.567,69.833 16.567,74.167 19.200,76.800 C21.833,79.433 26.167,79.433 28.800,76.800 C35.200,68.800 41.600,60.800 48.000,52.800 L67.200,76.800 C69.833,79.433 74.167,79.433 76.800,76.800 C79.433,74.167 79.433,69.833 76.800,67.200 C68.800,60.800 60.800,54.400 52.800,48.000 L76.800,28.800 C79.433,26.167 79.433,21.833 76.800,19.200 C74.167,16.567 69.833,16.567 67.200,19.200 C60.800,27.200 54.400,35.200 48.000,43.200 L28.800,19.200 C26.167,16.567 21.833,16.567 19.200,19.200 C16.567,21.833 16.567,26.167 19.200,28.800 C27.200,35.200 35.200,41.600 43.200,48.000 L19.200,67.200 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logError",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
