<template>
    <svg :copyright="this.$root.Copyright" :style="custom" @mouseleave.stop="leave" @mouseenter.stop="hover"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="16px" height="18px">
        <path fill-rule="evenodd"  :fill="fill"
              d="M1.000,2.800 L14.800,2.800 C15.129,2.800 15.400,3.071 15.400,3.400 C15.400,3.729 15.129,4.000 14.800,4.000 C10.200,4.000 5.600,4.000 1.000,4.000 C0.712,3.947 0.500,3.693 0.500,3.400 C0.500,3.107 0.712,2.853 1.000,2.800 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M6.100,0.700 L9.800,0.700 C10.029,0.818 10.183,1.045 10.200,1.300 C10.219,1.585 10.064,1.862 9.800,2.000 C8.567,2.000 7.333,2.000 6.100,2.000 C5.836,1.862 5.681,1.585 5.700,1.300 C5.717,1.045 5.871,0.818 6.100,0.700 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M2.000,3.400 L3.200,3.400 L3.200,15.100 C3.232,15.327 3.338,15.538 3.500,15.700 C3.662,15.862 3.873,15.968 4.100,16.000 C6.667,16.000 9.233,16.000 11.800,16.000 C12.027,15.968 12.238,15.862 12.400,15.700 C12.562,15.538 12.668,15.327 12.700,15.100 C12.700,12.000 12.700,8.900 12.700,5.800 C12.700,5.471 12.971,5.200 13.300,5.200 C13.629,5.200 13.900,5.471 13.900,5.800 C13.900,8.900 13.900,12.000 13.900,15.100 C13.932,15.712 13.682,16.313 13.200,16.700 C12.806,17.017 12.301,17.155 11.800,17.100 C9.233,17.100 6.667,17.100 4.100,17.100 C3.599,17.155 3.094,17.017 2.700,16.700 C2.218,16.313 1.968,15.712 2.000,15.100 C2.000,11.200 2.000,7.300 2.000,3.400 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M5.600,7.500 C5.589,7.091 5.934,6.773 6.300,6.800 C6.632,6.825 6.913,7.130 6.900,7.500 C6.900,9.167 6.900,10.833 6.900,12.500 C6.911,12.909 6.566,13.227 6.200,13.200 C5.868,13.175 5.587,12.870 5.600,12.500 C5.600,10.833 5.600,9.167 5.600,7.500 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M9.000,7.500 C8.987,7.130 9.268,6.825 9.600,6.800 C9.966,6.773 10.311,7.091 10.300,7.500 C10.300,9.167 10.300,10.833 10.300,12.500 C10.311,12.909 9.966,13.227 9.600,13.200 C9.268,13.175 8.987,12.870 9.000,12.500 C9.000,10.833 9.000,9.167 9.000,7.500 Z"/>
    </svg>
</template>

<script>
    export default {
        name: "trashCan",
        props:{
            custom:{
                type:String,
                default:null,
                required:false,
            }
        },
        data(){
            return {
                fill:'rgb(134,134,134)'
            }
        },
        methods:{
            hover(){
                this.fill='rgb(15,15,15)';
            },
            leave(){
                this.fill='rgb(134,134,134)';
            }
        }
    }
</script>

<style scoped>

</style>
