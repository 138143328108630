<template>
    <svg :copyright="this.$root.Copyright" :style="custom" @mouseleave.stop="leave" @mouseenter.stop="hover"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="19px" height="21px">
        <path fill-rule="evenodd"  :fill="fill"
              d="M0.750,2.750 L18.000,2.750 C18.411,2.750 18.750,3.089 18.750,3.500 C18.750,3.911 18.411,4.250 18.000,4.250 C12.250,4.250 6.500,4.250 0.750,4.250 C0.389,4.184 0.125,3.867 0.125,3.500 C0.125,3.133 0.389,2.816 0.750,2.750 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M7.125,0.125 L11.750,0.125 C12.036,0.273 12.228,0.557 12.250,0.875 C12.274,1.231 12.080,1.577 11.750,1.750 C10.208,1.750 8.667,1.750 7.125,1.750 C6.795,1.577 6.601,1.231 6.625,0.875 C6.647,0.557 6.839,0.273 7.125,0.125 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M2.000,3.500 L3.500,3.500 L3.500,18.125 C3.541,18.409 3.672,18.672 3.875,18.875 C4.078,19.078 4.341,19.209 4.625,19.250 C7.833,19.250 11.042,19.250 14.250,19.250 C14.534,19.209 14.797,19.078 15.000,18.875 C15.203,18.672 15.334,18.409 15.375,18.125 C15.375,14.250 15.375,10.375 15.375,6.500 C15.375,6.089 15.714,5.750 16.125,5.750 C16.536,5.750 16.875,6.089 16.875,6.500 C16.875,10.375 16.875,14.250 16.875,18.125 C16.915,18.890 16.602,19.641 16.000,20.125 C15.507,20.521 14.876,20.693 14.250,20.625 C11.042,20.625 7.833,20.625 4.625,20.625 C3.999,20.693 3.368,20.521 2.875,20.125 C2.273,19.641 1.960,18.890 2.000,18.125 C2.000,13.250 2.000,8.375 2.000,3.500 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M6.500,8.625 C6.486,8.114 6.918,7.716 7.375,7.750 C7.790,7.781 8.141,8.162 8.125,8.625 C8.125,10.708 8.125,12.792 8.125,14.875 C8.139,15.386 7.707,15.784 7.250,15.750 C6.835,15.719 6.484,15.338 6.500,14.875 C6.500,12.792 6.500,10.708 6.500,8.625 Z"/>
        <path fill-rule="evenodd"  :fill="fill"
              d="M10.750,8.625 C10.734,8.162 11.085,7.781 11.500,7.750 C11.957,7.716 12.389,8.114 12.375,8.625 C12.375,10.708 12.375,12.792 12.375,14.875 C12.389,15.386 11.957,15.784 11.500,15.750 C11.085,15.719 10.734,15.338 10.750,14.875 C10.750,12.792 10.750,10.708 10.750,8.625 Z"/>
    </svg>
</template>

<script>
    export default {
        name: "trashCan",
        props:{
            custom:{
                type:String,
                default:null,
                required:false,
            }
        },
        data(){
            return {
                fill:'rgb(134,134,134)'
            }
        },
        methods:{
            hover(){
                this.fill='rgb(15,15,15)';
            },
            leave(){
                this.fill='rgb(134,134,134)';
            }
        }
    }
</script>

<style scoped>

</style>
