<template>
  <layer-top-panel/>
  <layer-log/>
  <router-view/>
  <LayerFootPage/>
</template>

<script>
import LayerTopPanel from "./components/LayerTopPanel";
import LayerLog from "./components/LayerLog";
import LayerFootPage from "./components/LayerFootPage";

export default {
  name: 'App',
  components: {
    LayerTopPanel,
    LayerLog,
    LayerFootPage
  },
  mounted() {
    this.setDeviceType();
    this.handleOrientationChange();
    window.addEventListener('resize',()=>{this.handleOrientationChange()});
    window.addEventListener('orientationchange', ()=>{this.handleOrientationChange()});
    setInterval(()=>{this.$store.state.pageConfig.isMobile=/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)},5_000);
  },
  data(){
    return{
      Copyright:'Minxi Wan'
    }
  },
  methods:{
    handleOrientationChange(){//更新竖屏或横屏模式
      this.$store.state.pageConfig.vertical=window.innerWidth<=window.innerHeight;
      this.$store.state.pageConfig.resize=!this.$store.state.pageConfig.resize;
    },
    setDeviceType(){//设置设备类型是移动端还是电脑端
      this.$store.state.pageConfig.isMobile=/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>

<style>
html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,0.75);
  touch-action:none;
}
#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 4px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: rgba(0,0,0,0.2) !important;
}
::-webkit-scrollbar-track {
  border-radius: 0 !important;
  background: rgba(0,0,0,0.1) ;
}
* {
  -webkit-tap-highlight-color: transparent;
}
</style>
