<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="96px" height="96px">
    <path fill-rule="evenodd"  fill="rgb(62, 128, 248)"
          d="M48.000,0.000 C74.510,0.000 96.000,21.490 96.000,48.000 C96.000,74.510 74.510,96.000 48.000,96.000 C21.490,96.000 0.000,74.510 0.000,48.000 C0.000,21.490 21.490,0.000 48.000,0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M84.000,67.200 C60.000,67.200 36.000,67.200 12.000,67.200 C10.789,66.152 10.107,64.606 10.200,63.000 C10.281,61.601 10.943,60.308 12.000,59.400 C13.331,57.893 16.332,56.292 17.400,54.600 C21.207,48.569 21.379,41.596 21.600,34.200 C23.035,28.709 25.923,23.727 30.000,19.800 C34.901,15.080 41.232,12.763 48.000,12.000 C54.768,12.763 61.099,15.080 66.000,19.800 C70.077,23.727 72.965,28.709 74.400,34.200 C74.744,42.498 74.084,48.292 78.600,54.600 C79.345,55.641 83.145,58.441 84.000,59.400 C85.211,60.448 85.893,61.994 85.800,63.600 C85.719,64.999 85.057,66.292 84.000,67.200 ZM69.600,54.600 C65.207,48.347 65.709,42.481 66.000,34.200 C64.410,30.125 61.717,26.586 58.200,24.000 C55.195,21.791 51.698,20.356 48.000,19.800 C44.073,20.158 40.330,21.609 37.200,24.000 C33.816,26.585 31.311,30.145 30.000,34.200 C30.126,41.809 30.177,48.401 26.400,54.600 C25.505,56.069 23.304,58.070 22.200,59.400 L73.800,59.400 C72.936,58.448 70.336,55.648 69.600,54.600 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M34.800,78.600 C34.047,77.226 34.292,75.508 35.400,74.400 C36.508,73.292 38.226,73.047 39.600,73.800 C40.633,74.988 41.846,75.999 43.200,76.800 C44.682,77.677 46.305,78.285 48.000,78.600 C49.695,78.285 51.318,77.677 52.800,76.800 C54.154,75.999 55.367,74.988 56.400,73.800 C57.774,73.047 59.492,73.292 60.600,74.400 C61.708,75.508 61.953,77.226 61.200,78.600 C59.867,80.289 58.246,81.711 56.400,82.800 C53.855,84.301 50.971,85.116 48.000,85.200 C45.029,85.116 42.145,84.301 39.600,82.800 C37.754,81.711 36.133,80.289 34.800,78.600 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logNotice",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
