<template>
<div class="BananaUserLoginA" v-if="view && !vertical">
    <div class="leftA">
        <svg @click.stop="closeLoginBoard()"  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" class="iconA">
            <path  d="M617.92 516.096l272 272L788.096 889.92l-272-272-272 272L142.24 788.096l272-272-275.008-275.04L241.056 139.2l275.04 275.04 275.04-275.04L892.96 241.024l-275.04 275.04z" p-id="1475" data-spm-anchor-id="a313x.7781069.0.i0" fill="#ffffff" class="selected">
            </path>
        </svg>
        <span class="leftTxt5A">『After the</span>
        <span class="leftTxt5A">stars</span>
        <span class="leftTxt5A">wither』</span>
    </div>
    <div class="rightA">
        <div class="loginTitleA">
            通过QQ邮箱验证身份
        </div>
        <div class="loginDesA">
            输入您的QQ号，点击获取验证码，您将收到一封电子邮件，复制验证码到此提交验证即可。
        </div>
        <div class="loginInput1A">
            <span>ＱＱ号</span>
            <input placeholder="" class="Input2A" maxlength="12" v-model="qq_id"/>
        </div>
        <div class="loginInput1A">
            <span>验证码</span>
            <input placeholder="" class="Input3A" maxlength="6" v-model="code"/>
            <button class="buttonTypeA" @click.stop="getCode()" v-if="cd1===0">获取验证码</button>
            <button class="buttonTypeA" onclick="void false" v-if="cd1!==0">（{{cd1}}）</button>
        </div>
        <div class="loginInput1A">
            <div class="specialDsA" v-if="code===''">请输入验证码</div>
            <div class="specialDsA" v-if="code!==''">请提交验证码</div>
            <button class="buttonTypeA" @click.stop="verify()" v-if="cd2===0">提交验证</button>
            <button class="buttonTypeA" onclick="void false" v-if="cd2!==0">（{{cd2}}）</button>
        </div>
    </div>
</div>
<div class="BananaUserLoginB" v-if="view && vertical">
    <div class="rightB">
        <div class="loginTitleB">
            通过QQ邮箱验证身份
        </div>
        <div class="loginDesB">
            输入您的QQ号，点击获取验证码，您将收到一封电子邮件，复制验证码到此提交验证即可。
        </div>
        <div class="loginInput1A">
            <span>ＱＱ号</span>
            <input placeholder="" class="Input2A" maxlength="12" v-model="qq_id"/>
        </div>
        <div class="loginInput1A">
            <span>验证码</span>
            <input placeholder="" class="Input3A" maxlength="6" v-model="code"/>
            <button class="buttonTypeB" @click.stop="getCode()" v-if="cd1===0">获取验证码</button>
            <button class="buttonTypeB" onclick="void false" v-if="cd1!==0">（{{cd1}}）</button>
        </div>
        <div class="loginInput1A">
            <div class="specialDsA" v-if="code===''">请输入验证码</div>
            <div class="specialDsA" v-if="code!==''">请提交验证码</div>
            <button class="buttonTypeB" @click.stop="verify()" v-if="cd2===0">提交验证</button>
            <button class="buttonTypeB" onclick="void false" v-if="cd2!==0">（{{cd2}}）</button>
        </div>
    </div>
</div>
<div class="closeB" v-if="view && vertical" @touchend.stop="touchCloseLoginBoard()" @click.stop="closeLoginBoard()"/>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "BananaUserLogin",
        data(){
          return{
              qq_id:"",
              code:"",
              cd1:0,//获取验证码的cd 0 - 59
              cd2:0,//提交验证码的cd 0 - 10
              view:false,
          }
        },
        mounted(){
            let nowTime=Math.floor(Date.now()/1000);
            let cd1=window.localStorage.getItem('get_login_code_cd');
            let cd1_time=window.localStorage.getItem('get_login_code_cd_time');
            if(cd1===null || cd1_time===null){
                window.localStorage.setItem('get_login_code_cd','0');
                window.localStorage.setItem('get_login_code_cd_time','0');
                this.cd1=0;
            }
            else{
                cd1=parseInt(cd1);
                cd1_time=parseInt(cd1_time);
                if(isNaN(cd1) || isNaN(cd1_time)){
                    window.localStorage.setItem('get_login_code_cd','0');
                    window.localStorage.setItem('get_login_code_cd_time','0');
                    this.cd1=0;
                }else{
                    let gapTime=nowTime-cd1_time;
                    if(gapTime>=cd1){//时间差大于等于cd长度则cd归零
                        window.localStorage.setItem('get_login_code_cd','0');
                        window.localStorage.setItem('get_login_code_cd_time',nowTime+'');
                        this.cd1=0;
                    }else{
                        window.localStorage.setItem('get_login_code_cd',(cd1-gapTime)+'');
                        window.localStorage.setItem('get_login_code_cd_time',nowTime+'');
                        this.cd1=cd1-gapTime;
                    }
                }
            }
            let cd2=window.localStorage.getItem('verify_login_code_cd');
            let cd2_time=window.localStorage.getItem('verify_login_code_cd_time');
            if(cd2===null || cd1_time===null){
                window.localStorage.setItem('verify_login_code_cd','0');
                window.localStorage.setItem('verify_login_code_cd_time','0');
                this.cd2=0;
            }
            else{
                cd2=parseInt(cd2);
                cd2_time=parseInt(cd2_time);
                if(isNaN(cd2) || isNaN(cd2_time)){
                    window.localStorage.setItem('verify_login_code_cd','0');
                    window.localStorage.setItem('verify_login_code_cd_time','0');
                    this.cd2=0;
                }else{
                    let gapTime=nowTime-cd2_time;
                    if(gapTime>=cd2){//时间差大于等于cd长度则cd归零
                        window.localStorage.setItem('verify_login_code_cd','0');
                        window.localStorage.setItem('verify_login_code_cd_time',nowTime+'');
                        this.cd2=0;
                    }else{
                        window.localStorage.setItem('verify_login_code_cd',(cd2-gapTime)+'');
                        window.localStorage.setItem('verify_login_code_cd_time',nowTime+'');
                        this.cd2=cd2-gapTime;
                    }
                }
            }
            if(this.cd1>0){
                this.cd1Flow();
            }
            if(this.cd2>0){
                this.cd2Flow();
            }
        },
        computed:{
            vertical(){
                return this.$store.state.pageConfig.vertical;
            },
            loginBoardView(){
                return this.$store.state.pageConfig.loginBoardView;
            },
        },
        watch:{
            loginBoardView:{
                handler(newValue){
                    this.view=newValue;
                }
            }
        },
        methods:{
            verify(){
                if(/^\d{5,12}$/.test(this.qq_id)===false){
                    this.$store.commit('setCoLogMessage',{text:'QQ账号必须为5-12位数字，请修改后重试。',from:'internal:BananaUserLogin',type:'warn'});
                    return false;
                }
                if(/^\d{6}$/.test(this.code)===false){
                    this.$store.commit('setCoLogMessage',{text:'验证码必须为6位数字，请修改后重试。',from:'internal:BananaUserLogin',type:'warn'});
                    return false;
                }
                const params = new URLSearchParams();// 使用 URLSearchParams 构造表单数据
                params.append('qq_id',this.qq_id);
                params.append('code',this.code);
                axios.post('../php/api/public/int_php_login_phase2.php',params,
                    {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                    .then(response => {
                        if(response.data==='ok'){
                            this.$store.commit('setCoLogMessage',{text:'登录成功。',from:'internal:BananaUserLogin',type:'notice'});
                            if(this.$store.state.pageConfig.isMobile){
                                this.touchCloseLoginBoard();
                            }else {
                                this.closeLoginBoard();
                            }
                            this.updateLogin();
                        }else {
                            this.$store.commit('setCoLogMessage',{text:'验证码错误或失效。',from:'internal:BananaUserLogin',type:'error'});
                        }
                    })
                    .catch(function (error){ // 请求失败处理
                        console.log(error);
                    });
                this.cd2=10;
                this.cd2Flow();
            },
            touchCloseLoginBoard(){
                setTimeout(()=>this.$store.commit('setCoLoginBoardView',false),2);
            },
            closeLoginBoard(){//关闭面板
                if(this.$store.state.pageConfig.isMobile){return false;}
                setTimeout(()=>this.$store.commit('setCoLoginBoardView',false),2);
            },
            updateLogin(){//更新登录信息
                this.$emit('updateLogin',false);
            },
            getCode(){//获取验证码
                if(/^\d{5,12}$/.test(this.qq_id)===false){//修改这个正则表达式，要求QQ账号必须至少为5位，最大为12位数
                    this.$store.commit('setCoLogMessage',{text:'QQ账号必须为5-12位数字，请修改后重试。',from:'internal:BananaUserLogin',type:'warn'});
                    return false;
                }
                const params = new URLSearchParams();// 使用 URLSearchParams 构造表单数据
                params.append('qq_id',this.qq_id);
                axios.post('../php/api/public/int_php_login_phase1.php',params,
                    {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                    .then(response => {
                        if(response.data==='ok'){
                            this.$store.commit('setCoLogMessage',{text:'验证码已发送，请查收。',from:'internal:BananaUserLogin',type:'tip'});
                        }else {
                            this.$store.commit('setCoLogMessage',{text:'验证码获取失败，请稍后再试。',from:'internal:BananaUserLogin',type:'tip'});
                        }
                    })
                    .catch(function (error){ // 请求失败处理
                        console.log(error);
                    });
                this.cd1=119;
                this.cd1Flow();
            },
            cd1Flow(){//cd1 流动
                setTimeout(
                ()=>{
                    if(this.cd1!==0){
                        this.cd1--;
                        let time=Math.floor(Date.now()/1000);
                        window.localStorage.setItem('get_login_code_cd_time',time+'');
                        window.localStorage.setItem('get_login_code_cd',this.cd1+'');
                        if(this.cd1!==0){this.cd1Flow();}
                    }else{
                        let time=Math.floor(Date.now()/1000);
                        window.localStorage.setItem('get_login_code_cd_time',time+'');
                        window.localStorage.setItem('get_login_code_cd','0');
                    }
                }
                ,1_000)
            },
            cd2Flow(){//cd2 流动
                setTimeout(
                    ()=>{
                        if(this.cd2!==0){
                            this.cd2--;
                            let time=Math.floor(Date.now()/1000);
                            window.localStorage.setItem('verify_login_code_cd_time',time+'');
                            window.localStorage.setItem('verify_login_code_cd',this.cd2+'');
                            if(this.cd2!==0){this.cd2Flow();}
                        }else{
                            let time=Math.floor(Date.now()/1000);
                            window.localStorage.setItem('verify_login_code_cd_time',time+'');
                            window.localStorage.setItem('verify_login_code_cd','0');
                        }
                    }
                    ,1_000)
            }
        }
    }
</script>

<style scoped>
/*AB css start*/
.buttonTypeA{
    background: #07285a;
    color: #ffffff;
    border-radius: 3px;
    transition: 0.3s;
    border: 1px solid #07285a;
    cursor: pointer;
}
.buttonTypeB{
    background: #1268f0;
    color: #ffffff;
    border-radius: 3px;
    transition: 0.3s;
    border: 1px solid #07285a;
    cursor: pointer;
}
.buttonTypeA:hover{
    background: #8087d8;
}
.buttonTypeA:active{
    background: #0b3e8b;
}
.BananaUserLoginA{
    width: 600px;
    height: 370px;
    position: fixed;
    z-index: 65;
    box-shadow: 0px 0px 6px rgba(0,0,0,0.19);
    left: calc(50% - 300px);
    top: calc(50% - 195px);
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.BananaUserLoginB{
    width: 90%;
    height: 370px;
    position: fixed;
    z-index: 65;
    box-shadow: 0px 0px 6px rgba(0,0,0,0.19);
    left: 5%;
    top: calc(50% - 195px);
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.leftA{
    width: 200px;
    height: 100%;
    background-image: url("../../static/img/other/login_left.jpg");
    background-size: 100% auto;
    padding: 10px 0px 10px 10px;
    color: white;
    font-size: 20px;
    font-weight: 800;
}
.closeB{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 60;
    left: 0;
    top: 0;
    animation:toBlur 0.5s forwards;
}
@keyframes toBlur{
    from{
        backdrop-filter: blur(0px);
    }
    to{
        backdrop-filter: blur(2px);
    }
}
.rightA{
    width: calc(100% - 210px - 70px );
    height: calc(100% - 60px);
    background:white;
    padding: 40px 35px 20px 35px;
}
.rightB{
    width: calc(100% - 70px );
    height: calc(100% - 60px);
    padding: 40px 35px 20px 35px;
}
.iconA{
    margin: 10px 0px;
    user-select: none;
    cursor: pointer;
}
.iconB{
    margin: 0px;
    user-select: none;
    cursor: pointer;
}
.leftTxt5A{
    user-select: none;
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.leftTxt5A:nth-child(2){
    margin-top: 80px;
    transform: translateX(-20px);
}
.leftTxt5A:nth-child(3){
    transform: translateX(0px);
}
.leftTxt5A:nth-child(4){
    transform: translateX(10px);
}
.loginTitleA{
    width: calc(100% - 10px);
    height: 40px;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0,0,0,0.55);
    font-size: 24px;
    font-weight: 800;
}
.loginTitleB{
    width: calc(100% - 10px);
    height: 40px;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0,0,0,0.6);
    font-size: 24px;
    font-weight: 800;
}
.loginDesA{
    width: calc(100% - 10px);
    height: 100px;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0,0,0,0.5);
    font-size: 15px;
    font-weight: 400;
}
.loginDesB{
    width: calc(100% - 10px);
    height: 100px;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: rgba(0,0,0,0.7);
    font-size: 15px;
    font-weight: 400;
}
.loginInput1A{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0,0,0,0.7);
}
.specialDsA{
    width: 166px;
    font-size: 16px;
}
.loginInput1A span{
    margin-right: 10px;
}
.loginInput1A button{
    width: 90px;
    height: 27px;
    margin-left: 10px;
}
.Input2A{
    width: 200px;
    height: 22px;
    font-size: 16px;
    line-height: 16px;
}
.Input3A{
    width: 100px;
    height: 22px;
    font-size: 16px;
    line-height: 16px;
}
/*AB css end*/
/*通用css start*/
/*通用css end*/
</style>