import { createStore } from 'vuex';

export default createStore({
    state: {
        logConfig:{
            message:{
                code:1,
                time:'',
                text:'',
                from:'',//from:'[internal:name ] | [external:name]'
                type:'',//warn | error
                data:undefined,//异常处理数据
            },
            showPanel:false,
        },
        mapConfig:{
            inputFocusStatus:false,
        },
        pageConfig:{
            vertical:false,//屏幕方向 true为竖向false为横向 A:横屏 B:竖屏
            resize:true,//!resize to trigger watcher
            loginBoardView:false,
            isMobile:false,
            pageName:'',
        },
        userData:{
            qq_id:null,
        }
    },
    mutations: {
        setCoPageName(state,product){//product:page name
            state.pageConfig.pageName=product;
        },
        setCoLogMessage(state,product){//product:{text:'text',from:'[internal:name ] | [external:name]',type:'warn | error'}
            let formatDate=(date)=>{
                let y=date.getFullYear();
                let m=date.getMonth()+1;
                let d=date.getDate();
                let h=date.getHours();
                let u=date.getMinutes();
                let s=date.getSeconds();
                return `${y}-${m}-${d} ${h}:${u}:${s}`;
            };
            state.logConfig.message.text=product.text;
            state.logConfig.message.from=product.from;
            state.logConfig.message.type=product.type;
            state.logConfig.message.data=product.data;
            state.logConfig.message.time=formatDate(new Date());
            state.logConfig.message.code+=1;//code更新应在末尾
        },
        setCoLogShowPanel(state,product){//product:true/false
            state.logConfig.showPanel=product;
        },
        setCoLoginBoardView(state,product){//product:true/false
            state.pageConfig.loginBoardView=product;
        },
        setDaUserQq_id(state,product){//product:null/'123456789'
            state.userData.qq_id=product;
        },
    },
    actions: {
        // 定义你的动作
        // increment(context) {
        //     context.commit('increment');
        // }
    },
    getters: {
        // 定义你的getters
        //getCount: state => state.count
    }
});
