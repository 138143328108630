<template>
    <div :class="vertical?'SeparateContentB':'SeparateContentA'" id="SeparateContent">
        <layer-data-sheet v-if="pageName==='sheet'"/>
        <layer-diary v-if="pageName==='diary'"/>
        <layer-chat v-if="pageName==='chat'"/>
        <layer-about v-if="pageName==='about'"/>
    </div>
</template>

<script>
import LayerDataSheet from "./LayerDataSheet";
import LayerDiary from "./LayerDiary";
import LayerAbout from "./LayerAbout";
import LayerChat from "./LayerChat";
export default {
    name: "SeparateContent",
    components:{
      LayerDataSheet,LayerDiary,LayerAbout,LayerChat
    },
    data(){
        return{

        }
    },
    mounted(){
        this.$store.commit('setCoPageName',this.pageName);
    },
    computed:{
        vertical(){
            return this.$store.state.pageConfig.vertical;
        }
    },
    props:{
        pageName:{
            type:String,
            required:true,
            default:'sheet',
        }
    },
    watch:{
        pageName:{
            handler(newValue){
                this.$store.commit('setCoPageName',newValue);
                if(newValue==='map'){
                    window.open("https://map.atsw.top/");
                }
                if(newValue==='gitEa'){
                    window.open("http://atsw.top:3000/");
                }
            }
        }
    }
}
</script>

<style scoped>
/*AB css start*/
.SeparateContentA{/*横屏*/
    width: calc(100% - 4px);
    min-height: calc(100% - 50px);
    padding-top: 50px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: content-box;
    padding-right: 4px;
}
.SeparateContentB{
    width: calc(100% - 42px);
    height: 100%;
    padding-left: 42px;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: content-box;
}
/*AB css end*/
/*通用css start*/

/*通用css end*/
</style>