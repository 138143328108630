<template>
    <div class="LayerFootPageA" v-if="!vertical">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53062602000121" style="width: auto; height: 34px; display: flex; justify-content: center; align-items: center;">
            <img  src="../../static/img/icon/batb.png" alt="" width="15px" height="15px">
            滇公网安备 53062602000121号&nbsp;&nbsp;
        </a>
        ATSW萌的日志@2020-2024&nbsp;&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn">
            滇ICP备20002587号-2&nbsp;&nbsp;
        </a>
        商业合作电邮：emilia-t@qq.com
    </div>
    <div class="LayerFootPageB" v-if="vertical">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53062602000121" style="width: auto; height: 34px; display: flex; justify-content: center; align-items: center;">
            <img  src="../../static/img/icon/batb.png" alt="" width="12px" height="12px">
            滇公网安备 53062602000121号&nbsp;&nbsp;
        </a>
        <a target="_blank" href="https://beian.miit.gov.cn">
            滇ICP备20002587号-2
        </a>
    </div>
</template>

<script>
    export default {
        name: "LayerFootPage",
        computed:{
            vertical(){
                return this.$store.state.pageConfig.vertical;
            }
        }
    }
</script>

<style scoped>
/*AB css start*/
.LayerFootPageA{
    width: 100%;
    height: 30px;
    background: transparent;
    position: fixed;
    z-index: 80;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.3);
    font-size: 14px;
}
.LayerFootPageB{
    width: 100%;
    height: 20px;
    background: transparent;
    position: fixed;
    z-index: 80;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.3);
    font-size: 12px;
}
/*AB css end*/
a{
    text-decoration: none;
    color: rgba(0,0,0,0.3);
}
</style>