<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="96px" height="96px">
    <path fill-rule="evenodd"  fill="rgb(11, 90, 234)"
          d="M48.000,0.000 C74.510,0.000 96.000,21.490 96.000,48.000 C96.000,74.510 74.510,96.000 48.000,96.000 C21.490,96.000 0.000,74.510 0.000,48.000 C0.000,21.490 21.490,0.000 48.000,0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M48.000,9.000 C52.971,9.000 57.000,13.029 57.000,18.000 C57.000,22.971 52.971,27.000 48.000,27.000 C43.029,27.000 39.000,22.971 39.000,18.000 C39.000,13.029 43.029,9.000 48.000,9.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M39.000,36.000 L57.000,36.000 C54.867,44.665 54.052,53.533 54.600,62.400 C54.925,67.663 55.728,72.870 57.000,78.000 C60.514,77.625 63.138,80.386 63.000,82.800 C62.870,85.072 60.271,87.406 57.000,87.000 C51.000,87.000 45.000,87.000 39.000,87.000 C35.729,87.406 33.130,85.072 33.000,82.800 C32.862,80.386 35.486,77.625 39.000,78.000 C40.272,72.870 41.075,67.663 41.400,62.400 C41.948,53.533 41.133,44.665 39.000,36.000 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logTip",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
