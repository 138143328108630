<template>
    <div class="aboutApp">
        <div :class="vertical?'aboutAppBoxB':'aboutAppBoxA'">
            <div id="big_font"><p>关于</p></div>
            <hr/>
            <div id="content">
                <p class="big_p">
                    什么是ATSW ？
                    <br>
                </p>
                <p class="p_content">
                    在星空枯萎之后(<span class="sp_1">A</span>fter <span class="sp_1">T</span>ht <span class="sp_1">S</span>tars <span class="sp_1">W</span>ither)是一个由崩坏三玩家相聚在一起，逐渐形成的<span class="sp_1">玩家团体</span>(以下简称ATSW)，目前主要由<span class="sp_1">崩坏三</span>、<span class="sp_1">原神</span>、<span class="sp_1">公主连结</span>、<span class="sp_1">崩坏：星穹铁道</span>等玩家组成。
                    <br>
                    <br>
                    其中：
                    <br>
                    崩坏三玩家占据41% 较2021年下降31%
                    <br>
                    原神玩家占据18% 较2021年下降10%
                    <br>
                    PCR玩家占据26% 较2021年上升11%
                    <br>
                    其余类型玩家占据15% 包含(崩坏：星穹铁道、绝区零、鸣潮、影之诗)
                    <br>
                    <br>
                    *请注意部分玩家多款游戏都在玩，数据截至2024年7月23日，数据仅供参考，数据根据已收集到的资料整理加权所得
                    <br>
                    *数据来源https://docs.qq.com/sheet/DT2ZRZk1uSVdxYmN2
                </p>
                <p class="big_p">
                    ATSW的愿景是什么？
                </p>
                <p class="p_content">
                    围绕二次元<span class="sp_1">文化</span>、<span class="sp_1">游戏</span>，为玩家建立<span class="sp_1">文明和谐</span>、<span class="sp_1">积极向上</span>、<span class="sp_1">团结互助</span>的虚拟社区交流场所。
                </p>
                <p class="big_p">
                    ATSW对玩家的祝愿？
                </p>
                <p class="p_content">
                    祝愿各位玩家们能<span class="sp_1">和平相处</span>、<span class="sp_1">求同存异</span>，在喜爱的游戏中，<span class="sp_1">寻欢作乐</span>、<span class="sp_1">互帮互助</span>、<span class="sp_1">欧气满满</span>！୧((〃•̀ꇴ•〃))૭⁺✧
                </p>
                <p class="big_p">
                    ATSW的秩序是如何维护的？
                </p>
                <p class="p_content">
                    ATSW是一个向往自由的团体，任何人都可以自发的、发自真心的为美好的社区而默默守护，引导成员之间和谐相处。
                    <br>
                    <br>
                    <br>
                    <br>
                </p>
                <p class="big_p">
                    如何加入ATSW？
                </p>
                <p class="p_content">
                    崩坏三「在星空枯萎之后」舰团ID：13006368
                    <br>
                    公主连结 工会名：在星空枯萎之后 会长：伊宛
                </p>
                <p class="big_p">
                    其他
                </p>
                <p class="p_content">
                    <span class="sp_2">开源代码</span>：<a href="https://github.com/emilia-t/After-the-stars-wither">https://github.com/emilia-t/After-the-stars-wither</a>
                    <br>
                    <span class="sp_2">版本信息</span>：WebVersion-5.0.1-vue3
                    <br>
                    <span class="sp_2">公用API</span> ：<a href="https://atsw.top/api.html">https://atsw.top/api.html</a>
                    <br>
                    <span class="sp_2">鸣谢</span>： EMT、重装小兔05号机、冗談等
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aboutApp",
        data(){
            return {}
        },
        computed:{
            vertical(){
                return this.$store.state.pageConfig.vertical;
            }
        }
    }
</script>

<style scoped>
    #big_font p {
        text-align: center;
    }
    .aboutApp{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .aboutAppBoxA{
        width: 80%;
    }
    .aboutAppBoxB{
        width: 80%;
    }
    #big_font {
        width: 100%;
        height: auto;
        font-size: 40px;
    }
    #big_font p{margin: 5px}
    hr {
        height: 2px;
        width: 100%;
        border: none;
        border-top: 2px dotted #6464f4;
    }
    #content {
        width: 100%;
        margin: 0 auto;
    }
    .big_p {
        font-weight: bold;
        font-size: 20px;
    }
    .p_content {
        font-size: 16px;
    }
    .sp_1 {
        color: #ff5967;
        font-weight: bold;
    }
</style>