<!--从旧项目迁移的组件-->
<template>
    <div class="LayerDiary">
        <div style="display: none">
            <img :src="png_round_1">
        </div>
        <!--预览层-->
        <transition name="frame">
            <div ref="show_frame" v-show="show_show_frame"><!--显示相应的事件的框架-->
                <div ref="frame_time_type"><!--时间及类型-->
                </div>
                <div ref="frame_content"><!--部分内容前20个字-->
                </div>
            </div>
        </transition>
        <transition name="triangle">
            <div ref="triangle" v-show="show_show_frame"><!--小三角-->
            </div>
        </transition>
        <!--时间轴层-->
        <div :id="vertical?'time_axis_B':'time_axis_'">
            <img :src="png_axis_" ref="axis_" draggable="false"  style="top: 47.04px;height:666.4px;width:3px">
            <img :id="vertical?'round_B':'round'" ref="round"  :src="png_round" draggable="true" @touchmove="touch_move($event)" @drag.prevent="move($event)" @dragend.prevent="moveEnd()">
        </div>
        <!--视图层-->
        <div class="outlineBox" ref="flex" draggable="false">
            <div :class="vertical?'outline_B':'outline'" ref="outline" v-for="(diary,index) in list" :key="index" @mouseover="round_top_change($event.fromElement)" ><!--单个日志-->
                <div class="date"><!--时间及类型-->
                    <b>{{diary.time.year}}年{{diary.time.month}}月{{diary.time.day}}日{{diary.time.hours}}时{{diary.time.minute}}分({{diary.type}})</b>
                </div>
                <div class="content" v-for="(diary_content ,index) in diary.content" :key="index"><!--内容---->
                    <div class="text" v-if="create_text(diary_content.type)">
                        <p v-html="diary_content.text"></p>
                    </div>
                    <div class="img_div" v-if="create_img(diary_content.type)">
                        <img draggable="false" class="img" :src="'img/'+diary_content.text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import png_round from "../assets/img/diary/round_2.png";
    import png_round_1 from "../../static/img/icon/round.png";
    import png_axis_ from "../assets/img/diary/axis.png";
    import axios from 'axios'
    export default {
        name: "LayerDiary",
        data(){
            return{
                png_round,png_axis_,png_round_1,
                public__public:[],
                private__private:[],//用于存放私人数据
                list:[
                    {
                        "time": {
                            "year": "加载中...",
                            "month": "加载中...",
                            "day": "加载中...",
                            "hours": "加载中...",
                            "minute": "加载中..."
                        },
                        "type": "加载中...",
                        "content": [
                            {
                                "text": "加载中...",
                                "type": "text"
                            }
                        ]
                    },
                ],
                show_show_frame:false,
                end_show_frame_time:0,
                all_interval:[],
                initial_time:1586055240,//初始时间
                axis__top_config:[],//时间轴节点数据
                now_round_top_value:0,//当前小球的高度
                now_axis__height:0,//当前时间轴的高度
                now_round_min_top_value:0,//小球在最顶部的高度
                now_px_to_hours:0,//当前时间戳每像素对应的秒数
                window_height:0,
                window_width:0,
                //flex_scroll_max:this.$refs.flex.scrollHeight-this.$refs.flex.offsetHeight//flex的scroll最大值
            }
        },
        computed:{
            vertical(){
                return this.$store.state.pageConfig.vertical;
            },
        },
        //初始化
        mounted(){
            this.getChromeSize();//获取浏览器宽高
            this.setaxis_Style();//设置时间轴样式
            this.getDiaryData();//获取日志数据
            this.setaxis_Node();//设置时间戳节点数据
        },
        methods:{
            //编译服务端发来的日志数据
            compileDiaryData(sqlServerReturnData) {
                let endDataArr=[];
                for(let i=0;i<sqlServerReturnData.length;i++){
                    try{
                        let diaryId="did"+sqlServerReturnData[i][0];//did
                        let diaryDate=eval("("+sqlServerReturnData[i][1]+")");//date
                        let diaryType=sqlServerReturnData[i][2];//type
                        let diaryContent=eval("("+sqlServerReturnData[i][3]+")");
                        let endData={"did":diaryId,"time":diaryDate,"type":diaryType,"content":diaryContent};
                        endDataArr.unshift(endData);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
                return endDataArr;
            },
            //获取日志数据
            getDiaryData(){
                axios.get('../php/api/public/int_mysql_get_diary_data.php')
                    .then(response => {this.list=this.compileDiaryData(response.data);console.log(this.list)})
                    .catch(function (error) { // 请求失败处理
                    console.log(error);
                });
            },
            //鼠标悬浮在outline上时更新时间戳小球的位置
            round_top_change(diary_element) {
                this.$refs.round.style.transition='0.4s';
                for(let i=0;i<this.$refs.outline.length;i++){
                    if(diary_element==this.$refs.outline[i]){
                        this.$refs.round.style.top=(this.axis__top_config[i]+this.window_height*0.1)+'px';
                    }
                }
            },
            //初始化浏览器窗口尺寸
            getChromeSize(){
                this.window_height=window.innerHeight;
                this.window_width=window.innerWidth;
            },
            //设置轴样式（初始化和窗口改变时用）
            setaxis_Style(){
                //轴体顶部距离浏览器视窗高度为10%+10px
                //球体直径24px
                try{
                    if(this.$refs.axis_){
                        this.$refs.axis_.setAttribute('style','position:fixed;height:'+this.window_height*0.8+'px;width:3px;top:'+(this.window_height*0.1+10)+'px');
                    }
                    if(this.$refs.round){
                        this.$refs.round.setAttribute('style','position:fixed;height:24px;width:24px;top:'+(this.window_height*0.1+10-12)+'px');
                    }
                }catch (e) {
                    console.log(e);
                }
            },
            //传入一个xx年xx月xx日 xx时xx分xx秒，然后返回一个unix时间戳(s)
            getUnixTimeStamp(year,month,day,hour,minute,second){
                return (Date.parse(new Date(year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second)))/1000
            },
            //设置轴体节点数据（初始化和窗口改变时用）
            setaxis_Node(){
                if(this.$refs.axis_===undefined){
                    setTimeout(()=>this.setaxis_Node(),0);
                    return false;
                }
                try{
                    //1计算轴体时间抽象长度
                    let firstDate=this.list[0].time;
                    let lastDate=this.list[this.list.length-1].time;
                    //1.2转化为时间戳单位s     (Date.parse(new Date(lastDate.year+'-'+lastDate.month+'-'+lastDate.day+' '+lastDate.hours+':'+lastDate.minute)))/1000;
                    let firstDateTime=this.getUnixTimeStamp(firstDate.year,firstDate.month,firstDate.day,firstDate.hours,firstDate.minute,'00');
                    let lastDateTime=this.getUnixTimeStamp(lastDate.year,lastDate.month,lastDate.day,lastDate.hours,lastDate.minute,'00');
                    //2获取轴体实际高度和抽象高度
                    let rect=this.$refs.axis_.getBoundingClientRect();
                    let axis_Height=rect.height;
                    let axis_DateHeight=firstDateTime-lastDateTime;
                    //3实际像素映射为时间
                    let pxToDate=Math.floor(axis_DateHeight/axis_Height);
                    //4循环遍历，转化为节点,注意，此处应该和diaryListReverse()保持逆方向，即从数组末尾到数组开头
                    for(let i=this.list.length-1;i>=0;i--){
                        if(this.list[i].type=="加载中..."){
                            //若数据还未加载完成则不进行节点设置
                            return false;
                        }
                        // console.log("++++");
                        // console.log("当前："+i);
                        // console.log(this.list[i]);
                        // console.log("++++");
                        let theDiaryDate=this.getUnixTimeStamp(this.list[i].time.year,this.list[i].time.month,this.list[i].time.day,this.list[i].time.hours,this.list[i].time.minute,'00')
                        let length=Math.abs(theDiaryDate-lastDateTime);
                        let pxLength=Math.abs(Math.floor(length/pxToDate));
                        this.axis__top_config.push(pxLength)
                    }
                }
                catch (e) {
                    console.log(e);
                }
            },
            touch_move(ev){
                ev.preventDefault();//阻止下滑刷新
                let clientY=ev.targetTouches[0].clientY;
                if(clientY===0){return}//若鼠标再顶部，不移动
                if(clientY<=(window.innerHeight*0.1)-2 || clientY>=(window.innerHeight *0.9)){return}//若鼠标移出时间轴外，不移动
                this.$refs.round.style.transition='0s';//小球立即随鼠标移动而移动
                this.$refs.round.style.top=(clientY)+'px';//修改小球的高度
                this.now_round_top_value=clientY;
                for(let z=0;z<this.axis__top_config.length;z++){//查找节点高度信息，备用作匹配
                    if(Math.floor((clientY)-this.window_height*0.1+1)===this.axis__top_config[z]){ //如果移动到了节点处则做动作
                        this.show_show_frame=!this.show_show_frame;
                        this.$refs.frame_content.innerText=this.list[z].content[0].text.substring(0,60)+'……';//显示部分信息
                        let time_and_type=this.$refs.frame_time_type.innerText=this.list[z].time.year+'年'+this.list[z].time.month+'月'+this.list[z].time.day+'日'+this.list[z].time.hours+'时'+this.list[z].time.minute+'分('+this.list[z].type+')';//显示时间及类型信息
                        for(let k=0;k<this.$refs.outline.length;k++){
                            if(time_and_type==this.$refs.outline[k].firstElementChild.innerText){
                                this.$refs.outline[k].scrollIntoView({
                                    block: 'end',
                                    behavior: 'smooth'
                                });
                                this.$refs.outline[k].classList.add('hover');
                                this.$refs.triangle.style.position='fixed';
                                this.$refs.triangle.style.top=clientY-5+'px';
                                this.$refs.triangle.style.right=85+'px';
                                this.$refs.triangle.style.opacity='0';
                                this.$refs.show_frame.style.position='fixed';
                                this.$refs.show_frame.style.top=clientY-25+'px';
                                this.$refs.show_frame.style.right=80+'px';
                                this.$refs.show_frame.style.opacity='0';
                            }
                        }
                    }
                }
            },
            //移动时间轴小球时的动作
            move(ev){
                let clientY=ev.clientY;
                if(clientY===0){return}//若鼠标再顶部，不移动
                if(clientY<=(window.innerHeight*0.1)-2 || clientY>=(window.innerHeight *0.9)){return}//若鼠标移出时间轴外，不移动
                this.$refs.round.style.transition='0s';//小球立即随鼠标移动而移动
                this.$refs.round.style.top=(clientY)+'px';//修改小球的高度
                this.now_round_top_value=clientY;
                for(let z=0;z<this.axis__top_config.length;z++){//查找节点高度信息，备用作匹配
                    if(Math.floor((clientY)-this.window_height*0.1+1)===this.axis__top_config[z]){ //如果移动到了节点处则做动作
                        this.show_show_frame=!this.show_show_frame;
                        this.$refs.frame_content.innerText=this.list[z].content[0].text.substring(0,60)+'……';//显示部分信息
                        let time_and_type=this.$refs.frame_time_type.innerText=this.list[z].time.year+'年'+this.list[z].time.month+'月'+this.list[z].time.day+'日'+this.list[z].time.hours+'时'+this.list[z].time.minute+'分('+this.list[z].type+')';//显示时间及类型信息
                        for(let k=0;k<this.$refs.outline.length;k++){
                            if(time_and_type==this.$refs.outline[k].firstElementChild.innerText){
                                this.$refs.outline[k].scrollIntoView({
                                    block: 'end',
                                    behavior: 'smooth'
                                });
                                this.$refs.outline[k].classList.add('hover');
                                this.$refs.triangle.style.position='fixed';
                                this.$refs.triangle.style.top=clientY-5+'px';
                                this.$refs.triangle.style.right=85+'px';
                                this.$refs.triangle.style.opacity='0';
                                this.$refs.show_frame.style.position='fixed';
                                this.$refs.show_frame.style.top=clientY-25+'px';
                                this.$refs.show_frame.style.right=80+'px';
                                this.$refs.show_frame.style.opacity='0';
                            }
                        }
                    }
                }
            },
            //停止移动小球的动作
            moveEnd(){

            },
            /*创建一个text的节点*/
            create_text:function(type){
                if(type==='text'){
                    return true
                }
            },
            /*创建一个img的节点*/
            create_img:function(type){
                if(type==='img'){
                    return true
                }
            }
        },
        watch:{
            //当list数据有更改时修改list build的数据
            list:{
                handler(val,oldVal){
                    if(oldVal!==undefined){
                        if(val.length==oldVal.length){
                            //新旧数据长度一致时不更新
                            return  false;
                        }
                    }
                    if (this.list==undefined || this.list=='' || this.list==null){
                        return false
                    }else {
                        if(this.list.length<=0){
                            return false
                        }
                        for(let i=0;i<this.list.length;i++){
                            for(let x=0;x<this.list[i].content.length;x++){
                                if(this.list[i].content[x].type==='img'){
                                    //this.list[i].content[x].text=require('../assets/img/diary/'+this.list[i].content[x].text);//注释掉以保留原始文件名
                                }
                            }
                        }
                    }
                    //this.list.reverse();
                    this.setaxis_Style();//设置时间轴样式
                    this.setaxis_Node();//设置时间戳节点数据
                },
                immediate:true,
                deep:true
            },
        }
    };

</script>

<style scoped>
    .LayerDiary{width: 100%;height: auto;display: flex;justify-content: center}
    /*vue动画*/
    .frame-enter-active{
        /* 设置进入时的transition属性 */
        animation: hide_show_frame 6s forwards;
    }
    .frame-leave-active{
        /* 设置离开时的transition属性 */
        animation: hide_show_frame 6s forwards;
    }
    .frame-enter {
        /* 设置以什么状态进入 */
        animation: hide_show_frame 6s forwards;
    }
    .frame-leave-to {
        /* 设置离开到什么状态 */
        animation: hide_show_frame 6s forwards;
    }

    .triangle-enter-active{
        /* 设置进入时的transition属性 */
        animation: hide_triangle 6s forwards;
    }
    .triangle-leave-active{
        /* 设置离开时的transition属性 */
        animation: hide_triangle 6s forwards;
    }
    .triangle-enter {
        /* 设置以什么状态进入 */
        animation: hide_triangle 6s forwards;
    }
    .triangle-leave-to {
        /* 设置离开到什么状态 */
        animation: hide_triangle 6s forwards;
    }
    /*pc版 triangle*/
    ::-moz-selection {background: #5f5f5f;color: #ffffff;font-weight: 900;font-size: 32px;}
    ::selection {background: #5f5f5f;color: #ffffff;font-weight: 900;font-size: 32px;}
    body,html{overflow-x:hidden;overflow-y: hidden}
    /*body::-webkit-scrollbar{display:none;}/*隐藏滚动条*/
    body{height: 100%}
    img,#show_frame,#message{-moz-user-select:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;}
    /*body::-webkit-scrollbar{width: 20px}*/
    #flex{width:100%;display: flex;flex-direction:column;flex-wrap:nowrap;justify-content:center;align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        scrollbar-face-color: #6c82ff;
        scrollbar-base-color: #6c82ff;
        scrollbar-shadow-color: #6c82ff;
        scrollbar-highlight-color: #6c82ff;
        scrollbar-track-color: #6c82ff;
        scrollbar-arrow-color: #6c82ff;
        min-width: 925px;
    }
    #frame_1a{
        position: fixed;
        right: 80px;
    }
    #frame_3a{
        position: fixed;
        right: 70px;
    }
    /*显示层*/
    .text{text-indent:2em;line-height: 30px}
    .outlineBox{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .outline{padding: 10px;width:60%;border-radius: 10px;margin-top: 20px;box-shadow:0px 0px 6px rgba(0, 0, 0, 0.19);background: #ffffff;transition:0.3s;font-size:20px}
    .outline_B{padding: 10px;width:80%;border-radius: 10px;margin-top: 20px;box-shadow:0px 0px 6px rgba(0, 0, 0, 0.19);background: #ffffff;transition:0.3s;font-size:14.5px}
    .outline:hover{padding:14px ;box-shadow:0px 0px 9px rgba(0, 0, 0, 0.29);background: #ffffff}

    .img_div{width: inherit;text-align: center;}
    .img{max-height: 400px;border-radius: 10px;max-width: 90%;box-shadow: 2px 2px 6px rgba(184, 184, 184, 0.85)}
    .date{text-align: center;line-height: 45px}
    /*预览层*/
    #frame_content{width: 200px;height: auto;}
    #show_frame{width: 0px;height: auto;display: flex;flex-direction: column;justify-content: center;align-items: center;background: rgba(222, 222, 222, 0.91);position: fixed;z-index: 40;top:50px;right: 80px;border-radius: 6px;font-size: 14px;overflow: hidden;}
    #triangle{
        border-top: 0px solid  rgba(222, 222, 222, 0.91);
        border-right: 0px solid  rgba(222, 222, 222, 0.91);
        border-bottom: 0px solid  rgba(0, 0, 0, 0);
        border-left: 0px solid  rgba(0, 0, 0, 0);
        position: fixed;top:90px;right: 71px;
        transform:rotate(45deg);
    }
    /*时间轴*/
    #time_axis_{height:100%;position:fixed;right:60px;z-index:10;}
    #time_axis_B{height:100%;position:fixed;right:25px;z-index:10;}
    #axis_{position: fixed;right:30px;}
    #round{position: fixed;right:46px;height:24px;width: 24px;transition: 0.4s;}
    #round_B{position: fixed;right:11px;height:24px;width: 24px;transition: 0.4s;}
    /*动画*/
    @keyframes message_hide {
        0%{
            top:-60px;
        }
        25%{
            top:0px;
        }
        75%{
            top:0px;
        }
        100%{
            top:-90px;
        }
    }
    @keyframes hide_show_frame {
        0%{background: rgba(255,255,255,0.8);border-radius: 5px;box-shadow:0 0 6px rgba(0,0,0,0.19);width: 240px;opacity: 0}
        20%{background: rgba(255,255,255,0.8);border-radius: 5px;box-shadow:0 0 6px rgba(0,0,0,0.19);width: 240px;opacity: 1}
        90%{background: rgba(255,255,255,0.8);border-radius: 5px;box-shadow:0 0 6px rgba(0,0,0,0.19);width: 240px;opacity: 1}
        100%{background: rgba(255,255,255,0.8);border-radius: 5px;box-shadow:0 0 6px rgba(0,0,0,0.19);width: 240px;opacity: 0}
    }
    @keyframes hide_triangle {
        0%{border-width:0px;}
        20%{border-width:10px;}
        90%{border-width:10px;}
        100%{border-width:0px;}
    }
    /*消息层*/
    #message{position: fixed;width: 100%;height:44px;z-index: 999;animation: message_hide  2.5s forwards;
        -moz-animation: message_hide  2.5s forwards;
        -webkit-animation: message_hide 2.5s forwards;
        -o-animation: message_hide  2.5s forwards;
        background: #ffb1b8;
        line-height: 42px;
    }
    #mes_title{width: 100%;height: 44px;text-align: center;}
</style>