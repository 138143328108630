<template>
    <div :class="vertical?'LayerTopPanelB':'LayerTopPanelA'" >
        <div class="contentA" v-if="!vertical">
            <div class="logBoxA">
                <img alt="LOG" src="../../static/img/icon/atsw_log2.png" class="logA"/>
            </div>
            <div class="describeA">
                <span class="spanA">^_^ 公益的网站</span>
                <span class="spanA">public welfare website</span>
            </div>
            <div class="menuA">
                <router-link class="routerLink" :to="`/${item.pageName}`" v-for="item in pageList" :key="item.id">
                    <div class="menuItemA"
                         :class="nowPicked===item.id?'pickedA':''"
                         v-text="item.name"
                         @click.stop="changePage(item.id)"/>
                </router-link>
            </div>
            <div class="userA" @click.stop="openLoginBoard()" v-if="user_qq===null">
                <img alt="LOG" src="https://q.qlogo.cn/g?b=qq&nk=1000001&s=640&mType=friendlist" class="qqHeadA"/>
                <span>登录</span>
            </div>
            <div class="userA" @click.stop="outLogin()" v-if="user_qq!==null">
                <img alt="LOG" :src="'https://q.qlogo.cn/g?b=qq&nk='+user_qq+'&s=640&mType=friendlist'" class="qqHeadA"/>
                <span>退出登录</span>
            </div>
        </div>
        <div class="contentB"  v-if="vertical">
            <div class="logBoxB">
                <img alt="LOG" src="../../static/img/icon/atsw_log2_mini.png" class="logB"/>
            </div>
            <div class="userB" @touchend.stop="openLoginBoard()" @click.stop="openLoginBoard()" v-if="user_qq===null">
                <img alt="LOG" src="https://q.qlogo.cn/g?b=qq&nk=100001&s=640&mType=friendlist" class="qqHeadB"/>
                登录
            </div>
            <div class="userB" @click.stop="outLogin()" v-if="user_qq!==null">
                <img alt="LOG" :src="'https://q.qlogo.cn/g?b=qq&nk='+user_qq+'&s=640&mType=friendlist'" class="qqHeadB"/>
                <span>登出</span>
            </div>
            <div class="menuB">
                <router-link class="routerLink" :to="`/${item.pageName}`" v-for="item in pageList" :key="item.id">
                    <div class="menuItemB"
                         :class="nowPicked===item.id?'pickedB':''"
                         v-text="item.name"
                         @click.stop="changePage(item.id)"/>
                </router-link>
            </div>
        </div>
    </div>
    <banana-user-login @updateLogin="updateLogin()"/>
</template>

<script>
    import BananaUserLogin from "./BananaUserLogin";
    import axios from 'axios'
    export default {
        name: "LayerTopPanel",
        components:{BananaUserLogin},
        data(){
            return{
                pageList:[
                    {name:'资料表',id:1,pageName:'sheet'},
                    {name:'日志',id:2,pageName:'diary'},
                    {name:'聊天',id:3,pageName:'chat'},
                    {name:'关于',id:4,pageName:'about'},
                    {name:'地图',id:5,pageName:'map'},
                    {name:'Git EA',id:6,pageName:'gitEa'},
                ],
                nowPicked:1,
                childTest:null,
            }
        },
        mounted() {
            let cookie=this.parseCookies();
            if(Object.prototype.hasOwnProperty.call(cookie,'user_qq_by_atsw')){
                let qq=cookie['user_qq_by_atsw'];
                if(/^\d+$/.test(qq)===true){
                    this.$store.commit('setDaUserQq_id',qq);
                }
            }
            setTimeout(
                ()=>{
                    let newPage=this.pageName;
                    for(let i=0;i<this.pageList.length;i++){
                        if(newPage===this.pageList[i].pageName){
                            this.changePage(this.pageList[i].id);
                        }
                    }
                }
            ,0);
        },
        methods:{
            updateLogin(){
                let cookie=this.parseCookies();
                if(Object.prototype.hasOwnProperty.call(cookie,'user_qq_by_atsw')){
                    let qq=cookie['user_qq_by_atsw'];
                    if(/^\d+$/.test(qq)===true){
                        this.$store.commit('setDaUserQq_id',qq);
                    }
                }
            },
            parseCookies() {
                const cookies = document.cookie.split(';'); // 分割 Cookie 字符串
                const cookieObject = {};
                cookies.forEach(cookie => {
                    const [name, value] = cookie.split('=');
                    const trimmedName = name.trim();
                    const decodedValue = decodeURIComponent(value);
                    if (trimmedName) {
                        cookieObject[trimmedName] = decodedValue;
                    }
                });
                return cookieObject;
            },
            changePage(id){
                this.nowPicked=id;
            },
            openLoginBoard(){
                setTimeout(
                    ()=>{
                        if(this.$store.state.pageConfig.loginBoardView===false){
                            this.$store.commit('setCoLoginBoardView',true);
                        }
                    }
                ,2);
            },
            outLogin(){
                axios.get('../php/api/public/int_php_logout_phase3.php')
                    .then(response => {
                        if(response.data==='ok'){
                            this.$store.commit('setDaUserQq_id',null);
                            this.$store.commit('setCoLogMessage',{text:'已退出登录。',from:'internal:LayerTopPanel',type:'tip'});
                        }else{
                            window.location.reload();
                        }
                    })
                    .catch(function (error){ // 请求失败处理
                        console.log(error);
                    });
            }
        },
        computed:{
            pageName(){
              return this.$store.state.pageConfig.pageName;
            },
            vertical(){
              return this.$store.state.pageConfig.vertical;
            },
            loginBoardView(){
                return this.$store.state.pageConfig.loginBoardView;
            },
            user_qq(){
                return this.$store.state.userData.qq_id;
            }
        },
        watch:{
            pageName:{
                handler(newValue){
                    for(let i=0;i<this.pageList.length;i++){
                        if(newValue===this.pageList[i].pageName){
                            this.changePage(this.pageList[i].id);
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
    /*AB css start*/
    .contentA{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #fcfcfc;
    }
    .contentB{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #fcfcfc;
    }
    .LayerTopPanelA{
        position: fixed;
        z-index: 60;
        top:0;
        left:0;
        width: 100%;
        height: 50px;
        box-shadow: 2px 2px 10px rgba(201, 201, 201, 0.86);
        font-size: 16px;
    }
    .LayerTopPanelB{
        position: fixed;
        top:0;
        left:0;
        width: 38px;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #fcfcfc;
        box-shadow: 2px 2px 10px rgba(201, 201, 201, 0.86);
        font-size: 14px;
    }
    .menuItemA{
        width: auto;
        border-bottom:2px solid #4f5fcb;
        padding: 8px;
        transition: 0.3s;
    }
    .menuItemB{
        width: auto;
        height: auto;
        border-right:2px solid #4f5fcb;
        padding: 6px 4px;
        transition: 0.3s;
    }
    .menuA{
        width: calc(100% - 140px - 150px - 290px);
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .menuB{
        width: 100%;
        writing-mode: vertical-lr;
        height: calc(100% - 50px - 120px - 2px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }
    .userA{
        cursor: pointer;
        width: 290px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .userB{
        user-select: none;
        cursor: pointer;
        width: 100%;
        height: 120px;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .qqHeadA{
        height: 30px;
        width: 30px;
        border-radius: 6px;
        box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
        margin: 0px 10px;
        transition: 0.3s;
    }
    .qqHeadA:hover{
        transform: scale(1.2);
    }
    .qqHeadB{
        width: 25px;
        height: 25px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
        margin: 10px 0px;
    }
    .describeA{
        width: 150px;
        height: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .spanA:nth-child(1){
        font-size: 13px;
    }
    .spanA:nth-child(2){
        font-size: 12px;
    }
    .logBoxA{
        width: 140px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center
    }
    .logBoxB{
        width: 80%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center
    }
    .logA{
        width:auto;
        height: 100%;
        margin: 0 10px
    }
    .logB{
        width:100%;
        height: auto;
        margin: 10px
    }
    .pickedA{
        font-weight: 600;
        color: #ddf3ff;
        background: #5d6eeb;
    }
    .pickedB{
        font-weight: 600;
        color: #ddf3ff;
        background: #5d6eeb;
    }
    /*AB css end*/

    /*通用css start*/
    .routerLink{
        text-decoration: none;
        color: #131313;
    }
    /*通用css end*/
</style>