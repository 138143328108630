<template>
    <div :class="vertical?'BananaSearchResultB':'BananaSearchResultA'" v-if="show">
        <div class="titleA">
            搜索结果
        </div>
        <div class="contentOutA" v-if="result.content.length!==0">
            <div class="contentA" v-for="(item,index) in result.content" :key="index">
                <img alt="" class="sheetIconA" src="../../static/img/icon/bh3log.jpg" v-if="item.sheet_name==='bh3'">
                <img alt="" class="sheetIconA" src="../../static/img/icon/pcrlog.jpg" v-if="item.sheet_name==='pcr'">
                <img alt="" class="sheetIconA" src="../../static/img/icon/yslog.jpg" v-if="item.sheet_name==='ys'">
                <img alt="" class="sheetIconA" src="../../static/img/icon/tag.png" v-if="item.sheet_name==='voluntarily'">
                <banana-card-item
                        :insert_time="item.insert_time"
                        :game_id="item.game_id"
                        :qq_id="item.qq_id"
                        :group_nickname="item.group_nickname"
                        :remarks="item.remarks"
                        :game_nickname="item.game_nickname"
                        :habit_name="item.habit_name"
                        v-if="item.sheet_name!=='voluntarily'"
                />
                <banana-card-item-voluntarily
                        :anonymous="item.anonymous"
                        :custom="item.custom"
                        :game_id="item.game_id"
                        :game_name="item.game_name"
                        :game_nickname="item.game_nickname"
                        :insert_time="item.insert_time"
                        :qq_id="item.qq_id"
                        :remarks="item.remarks"
                        :serial_number="item.serial_number"
                        v-if="item.sheet_name==='voluntarily'"
                        @deleteCard="removeCard"
                />
            </div>
        </div>
        <div class="emptyOutA" v-if="result.content.length===0">
            ~没有搜到相关信息~
        </div>
        <div class="buttonOutA">
            <button class="buttonA" @click.stop="show=false">关闭</button>
        </div>
    </div>
</template>

<script>
    import BananaCardItem from "./BananaCardItem";
    import BananaCardItemVoluntarily from "./BananaCardItemVoluntarily";
    export default {
        name: "BananaSearchResult",
        components: {BananaCardItem,BananaCardItemVoluntarily},
        computed:{
            vertical(){
                return this.$store.state.pageConfig.vertical;
            }
        },
        data(){
          return{
              show:false
          }
        },
        props:{
            result:{
                type:Object,
                default:function () {
                    return {
                        keyword:'',
                        content:[]
                    }
                },
                required:true
            }
        },
        methods:{
            removeCard(data){
                this.$emit('deleteCard',data);//转发
            },
        },
        watch:{
            result: {
                handler(newValue){
                    if(newValue.keyword!==''){
                        this.show=true;
                    }
                },
                deep:true
            }
        }
    }
</script>

<style scoped>
/*AB css start*/
.BananaSearchResultA{
    width: calc(420px - 20px);
    height: 680px;
    padding: 0px 10px;
    position: fixed;
    z-index: 60;
    left: calc(50% - 200px);
    top: calc(50% - 340px);
    border-radius: 10px;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.19);
    background: white;
}
.BananaSearchResultB{
    width:calc(90% - 20px);
    height: 680px;
    padding: 0px 10px;
    position: fixed;
    z-index: 60;
    left: 5%;
    top: calc(50% - 340px);
    border-radius: 10px;
    box-shadow: 2px 2px 6px rgba(0,0,0,0.19);
    background: white;
}
.titleA{
    font-size: 17px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.contentOutA{
    width: 100%;
    height: calc(100% - 50px - 60px);
    overflow-x: hidden;
    overflow-y: auto;
}
.contentA{
    width: 100%;
    height: auto;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.sheetIconA{
    width: 55px;
    height: 55px;
    border-radius: 10px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    animation:sheetIconAnimationA 0.5s forwards;
}
@keyframes sheetIconAnimationA{
    0%{
        opacity: 0;
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.emptyOutA{
    width: 100%;
    height: calc(100% - 50px - 60px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.buttonOutA{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.buttonA{
    cursor: pointer;
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 5px 20px;
    background-color: #7dc5ff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    border: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    outline: 0;
}
.buttonA:hover{
    background: #289ffe;
}
.buttonA:active{
    background: #ffffff;
    color: #000000;
}
/*AB css end*/

/*通用css start*/

/*通用css end*/
</style>