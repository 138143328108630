<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16px" height="16px">
    <path fill-rule="evenodd"  fill="rgb(11, 90, 234)"
          d="M8.000,-0.000 C12.418,-0.000 16.000,3.582 16.000,8.000 C16.000,12.418 12.418,16.000 8.000,16.000 C3.582,16.000 -0.000,12.418 -0.000,8.000 C-0.000,3.582 3.582,-0.000 8.000,-0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M8.000,1.500 C8.828,1.500 9.500,2.172 9.500,3.000 C9.500,3.828 8.828,4.500 8.000,4.500 C7.172,4.500 6.500,3.828 6.500,3.000 C6.500,2.172 7.172,1.500 8.000,1.500 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M6.500,6.000 L9.500,6.000 C9.144,7.444 9.009,8.922 9.100,10.400 C9.154,11.277 9.288,12.145 9.500,13.000 C10.086,12.938 10.523,13.398 10.500,13.800 C10.478,14.179 10.045,14.568 9.500,14.500 C8.500,14.500 7.500,14.500 6.500,14.500 C5.955,14.568 5.522,14.179 5.500,13.800 C5.477,13.398 5.914,12.938 6.500,13.000 C6.712,12.145 6.846,11.277 6.900,10.400 C6.991,8.922 6.856,7.444 6.500,6.000 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logTip",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
