<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="96px" height="96px">
    <path fill-rule="evenodd"  fill="rgb(233, 133, 62)"
          d="M48.000,0.000 C74.510,0.000 96.000,21.490 96.000,48.000 C96.000,74.510 74.510,96.000 48.000,96.000 C21.490,96.000 0.000,74.510 0.000,48.000 C0.000,21.490 21.490,0.000 48.000,0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M48.000,69.000 C52.971,69.000 57.000,73.029 57.000,78.000 C57.000,82.971 52.971,87.000 48.000,87.000 C43.029,87.000 39.000,82.971 39.000,78.000 C39.000,73.029 43.029,69.000 48.000,69.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M39.000,18.000 C39.000,13.063 43.063,9.000 48.000,9.000 C52.937,9.000 57.000,13.063 57.000,18.000 C55.400,32.000 53.800,46.000 52.200,60.000 C52.200,62.304 50.304,64.200 48.000,64.200 C45.696,64.200 43.800,62.304 43.800,60.000 C42.200,46.000 40.600,32.000 39.000,18.000 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logWarn",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
