<template>
    <div class="LayerDataSheetOut">
        <div class="LayerDataSheetA" v-if="!vertical">
            <div class="switchOutA"><!--switch sheet-->
                <div class="sheetIconOutA">
                    <img alt="" class="sheetIconA" src="../../static/img/icon/bh3log.jpg"
                         style="cursor:pointer" title="https://www.bh3.com"
                         v-if="selectSheet==='bh3'"
                        @click.stop="openBh3Page()"
                    />
                    <img alt="" class="sheetIconA" src="../../static/img/icon/pcrlog.jpg"
                         style="cursor:pointer" title="https://priconne-redive.jp"
                         v-if="selectSheet==='pcr'"
                         @click.stop="openPcrPage()"
                    />
                    <img alt="" class="sheetIconA" src="../../static/img/icon/yslog.jpg"
                         style="cursor:pointer" title="https://ys.mihoyo.com"
                         v-if="selectSheet==='ys'"
                         @click.stop="openYsPage()"
                    />
                    <img alt="" class="sheetIconA" src="../../static/img/icon/icon_latest.png"
                         v-if="selectSheet==='latest'"
                    />
                    <img alt="" class="sheetIconA" src="../../static/img/icon/add.png"
                         style="cursor:pointer" title="点击添加"
                         v-if="selectSheet==='voluntarily'"
                         @click.stop="openAddPanel()"
                    />
                </div>
                <div class="switchBoxA">
                    <div class="sheetNameA" :class="selectSheet===sheet.sheetName?'pickedSheetA':''"
                         v-for="sheet in sheetList"
                         :key="sheet.sheetName"
                         v-text="sheet.name"
                         @click.stop="changeSheet(sheet.sheetName)"/>
                </div>
                <div class="searchBoxA">
                    <div class="searchInputOutA">
                        <div class="searchTypeA">
                            <span v-if="!searchTypeListView">{{searchType}}</span>
                            <dropdown-button @click.stop="searchTypeListView=true" v-if="!searchTypeListView"/>
                            <div class="listOutA" v-if="searchTypeListView">
                                <div class="listItemA"
                                     v-for="(ty,index) in searchTypeList"
                                     :key="index"
                                     :class="searchType===ty?'pickedListA':''"
                                     v-text="ty"
                                     @click.stop="()=>{this.searchType=ty ; this.searchTypeListView=false}"
                                />
                            </div>
                            <div class="searchTypeIconA"></div>
                        </div>
                        <input class="searchInputA" type="text" maxlength="20"
                               v-model="searchText" :placeholder="searchTextDefault"
                               @keydown.enter="search()" @focusin="onFocusMode()" @focusout="noFocusMode()"
                        />
                    </div>
                    <img src="../../static/img/icon/sousuo.png" alt="" title="点击搜素" class="sousuoA" @click.stop="search()">
                </div>
            </div>
            <div class="latestDesA" v-if="selectSheet==='latest'">
                <img alt="" class="sheetIconA" src="../../static/img/icon/bh3log.jpg">
                <img alt="" class="sheetIconA" src="../../static/img/icon/pcrlog.jpg">
                <img alt="" class="sheetIconA" src="../../static/img/icon/yslog.jpg">
            </div>
            <div class="sheetContentA" v-if="selectSheet!=='voluntarily'">
                <banana-card-item
                        v-for="item in sheetData"
                        :key="item.serial_number"
                        :insert_time="item.insert_time"
                        :serial_number="item.serial_number"
                        :qq_id="item.qq_id"
                        :group_nickname="item.group_nickname"
                        :game_id="item.game_id"
                        :game_nickname="item.game_nickname"
                        :habit_name="item.habit_name"
                        :remarks="item.remarks"
                />
            </div>
            <div class="sheetVoluntarilyA" v-if="selectSheet==='voluntarily'">
                <div class="voluntarilyDesA">
                    <span class="voluntarilySpanA">
                        来自网友自行添加的资料，点击左侧＋按钮即可添加。禁止添加违法违规内容，<span class="attentionA" @mouseenter.stop="attentionTextShow=true" @mouseleave.stop="attentionTextShow=false">查看详细规则</span>。
                    </span>
                    <span class="voluntarilySpanA">
                        Materials added by netizens can be added by clicking the "+" button in the left corner. It is prohibited to add any illegal content.
                    </span>
                    <span class="attentionTextA" v-if="attentionTextShow">
                        在您自行添加资料之前，您需要完全检查您的信息，避免您上传的信息中包含以下信息：<br/>
                        1. 反对宪法所确定的基本原则的；<br/>
                        2. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
                        3. 损害国家荣誉和利益的；<br/>
                        4. 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
                        5. 破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
                        6. 散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
                        7. 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
                        8. 侮辱或者诽谤他人，侵害他们合法权益的；<br/>
                        9. 含有法律、行政法规禁止的其他内容的。
                    </span>
                </div>
            </div>
            <div class="sheetContentA" v-if="selectSheet==='voluntarily'">
                <banana-card-item-voluntarily
                        v-for="(item,index) in sheetData"
                        :key="item.index_number"
                        :index_number="index"
                        :anonymous="item.anonymous"
                        :insert_time="item.insert_time"
                        :serial_number="item.serial_number"
                        :qq_id="item.qq_id"
                        :game_id="item.game_id"
                        :game_nickname="item.game_nickname"
                        :game_name="item.game_name"
                        :custom="item.custom"
                        :remarks="item.remarks"
                        @deleteCard="deleteCard"
                />
            </div>
            <div class="pageNavigateA" v-if="selectSheet==='voluntarily' && pageCount>1">
                <div class="pageNumberA" @click.stop="pageBtUp()">上一页</div>
                <div :class="pageBtClass(num)"  v-for="num in pageList" v-text="num" :key="num" @click.stop="pageBtClick(num)"/>
                <div class="pageNumberA" @click.stop="pageBtDown()">下一页</div>
            </div>
        </div>
        <div class="LayerDataSheetB" v-if="vertical">
            <div class="switchOutB">
                <div class="level1B">
                    <div class="searchBoxB">
                        <div class="searchInputOutB">
                            <div class="searchTypeB">
                                <span v-if="!searchTypeListView">{{searchType}}</span>
                                <dropdown-button @click.stop="searchTypeListView=true" v-if="!searchTypeListView"/>
                                <div class="listOutB" v-if="searchTypeListView">
                                    <div class="listItemB"
                                         v-for="(ty,index) in searchTypeList"
                                         :key="index"
                                         :class="searchType===ty?'pickedListA':''"
                                         v-text="ty"
                                         @click.stop="()=>{this.searchType=ty ; this.searchTypeListView=false}"
                                    />
                                </div>
                                <div class="searchTypeIconA"></div>
                            </div>
                            <input class="searchInputB" type="text" maxlength="20"
                                   v-model="searchText" :placeholder="searchTextDefault"
                                   @keydown.enter="search()" @focusin="onFocusMode()" @focusout="noFocusMode()"
                            />
                        </div>
                        <img src="../../static/img/icon/sousuo.png" alt="" title="点击搜素" class="sousuoA" @click.stop="search()">
                    </div>
                </div>
                <div class="level1B">
                    <div class="sheetIconOutB">
                        <img alt="" class="sheetIconB" src="../../static/img/icon/bh3log.jpg"
                             style="cursor:pointer" title="https://www.bh3.com"
                             v-if="selectSheet==='bh3'"
                             @click.stop="openBh3Page()"
                        />
                        <img alt="" class="sheetIconB" src="../../static/img/icon/pcrlog.jpg"
                             style="cursor:pointer" title="https://priconne-redive.jp"
                             v-if="selectSheet==='pcr'"
                             @click.stop="openPcrPage()"
                        />
                        <img alt="" class="sheetIconB" src="../../static/img/icon/yslog.jpg"
                             style="cursor:pointer" title="https://ys.mihoyo.com"
                             v-if="selectSheet==='ys'"
                             @click.stop="openYsPage()"
                        />
                        <img alt="" class="sheetIconB" src="../../static/img/icon/icon_latest.png"
                             v-if="selectSheet==='latest'"
                        />
                        <img alt="" class="sheetIconB" src="../../static/img/icon/add.png"
                             style="cursor:pointer" title="点击添加"
                             v-if="selectSheet==='voluntarily'"
                             @touchend.stop="touchOpenAddPanel()"
                             @click.stop="openAddPanel()"
                        />
                    </div>
                    <div class="switchBoxB">
                        <div class="sheetNameB" :class="selectSheet===sheet.sheetName?'pickedSheetA':''"
                             v-for="sheet in sheetList"
                             :key="sheet.sheetName"
                             v-text="sheet.name"
                             @click.stop="changeSheet(sheet.sheetName)"/>
                    </div>
                </div>
            </div>
            <div class="latestDesB" v-if="selectSheet==='latest'">
                <img alt="" class="sheetIconB" src="../../static/img/icon/bh3log.jpg">➔
                <img alt="" class="sheetIconB" src="../../static/img/icon/pcrlog.jpg">➔
                <img alt="" class="sheetIconB" src="../../static/img/icon/yslog.jpg">
            </div>
            <div class="sheetContentB" v-if="selectSheet!=='voluntarily'">
                <banana-card-item
                        v-for="item in sheetData"
                        :key="item.serial_number"
                        :insert_time="item.insert_time"
                        :serial_number="item.serial_number"
                        :qq_id="item.qq_id"
                        :group_nickname="item.group_nickname"
                        :game_id="item.game_id"
                        :game_nickname="item.game_nickname"
                        :habit_name="item.habit_name"
                        :remarks="item.remarks"
                />
            </div>
            <div class="sheetVoluntarilyB" v-if="selectSheet==='voluntarily'">
                <div class="voluntarilyDesB">
                    <span class="voluntarilySpanA">
                        点击＋按钮添加, 禁止添加违法违规内容<span class="attentionA" @touchend.stop="attentionTextOpen()">点我查看详细</span>
                    </span>
                    <span class="attentionTextB" v-if="attentionTextShow">
                        在您自行添加资料之前，您需要完全检查您的信息，避免您上传的信息中包含以下信息：<br/>
                        1. 反对宪法所确定的基本原则的；<br/>
                        2. 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
                        3. 损害国家荣誉和利益的；<br/>
                        4. 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
                        5. 破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
                        6. 散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
                        7. 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
                        8. 侮辱或者诽谤他人，侵害他们合法权益的；<br/>
                        9. 含有法律、行政法规禁止的其他内容的。
                    </span>
                </div>
                <div class="voluntarilyDesCloseB" v-if="attentionTextShow" @touchend.stop="attentionTextClose()"/>
            </div>
            <div class="sheetContentA" v-if="selectSheet==='voluntarily'">
                <banana-card-item-voluntarily
                        v-for="(item,index) in sheetData"
                        :key="item.index_number"
                        :index_number="index"
                        :anonymous="item.anonymous"
                        :insert_time="item.insert_time"
                        :serial_number="item.serial_number"
                        :qq_id="item.qq_id"
                        :game_id="item.game_id"
                        :game_nickname="item.game_nickname"
                        :game_name="item.game_name"
                        :custom="item.custom"
                        :remarks="item.remarks"
                        @deleteCard="deleteCard"
                />
            </div>
            <div class="pageNavigateA" v-if="selectSheet==='voluntarily' && pageCount>1">
                <div class="pageNumberA" @click.stop="pageBtUp()">上一页</div>
                <div :class="pageBtClass(num)"  v-for="num in pageList" v-text="num" :key="num" @click.stop="pageBtClick(num)"/>
                <div class="pageNumberA" @click.stop="pageBtDown()">下一页</div>
            </div>
        </div>
        <div class="addPanelShow" v-show="addPanelShow">
            <div class="addPanelShowA" v-if="!vertical">
                <div class="addPanelLeftA">
                    <div class="addPanelLeftTxtA">预览 · Preview</div>
                    <div class="addPanelLeftCardA">
                        <banana-card-item-preview
                                :game_name="addPanelData.game_name"
                                :qq_id="addPanelData.qq_id"
                                :game_id="addPanelData.game_id"
                                :nickname="addPanelData.nickname"
                                :remarks="addPanelData.remarks"
                                :custom="addPanelData.custom"
                        />
                    </div>
                    <div class="addPanelLeftTxtA">规则 · Rule</div>
                    <div class="addPanelLeftRuleA">
                        <div class="addPanelLeftRowA">
                            ① 玩家ＩＤ必须为数字
                        </div>
                        <div class="addPanelLeftRowA">
                            ②<span style="color: #ff5663">&nbsp;红色字段&nbsp;&nbsp;</span>为必填项
                        </div>
                        <div class="addPanelLeftRowA">
                            ③&nbsp;自定义属性不能超过９行
                        </div>
                    </div>
                </div>
                <div class="addPanelRightA">
                    <div class="addPanelContentA">
                        <div class="addPanelRowA">
                            <div class="addRowLeftA" style="color: #ff5663">
                                游戏名称：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.game_name" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA">
                            <div class="addRowLeftA" style="color: #ff5663">
                                游戏昵称：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.nickname" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA">
                            <div class="addRowLeftA" style="color: #ff5663">
                                玩家ＩＤ：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.game_id" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA">
                            <div class="addRowLeftA">
                                备注信息：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.remarks" maxlength="40" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA" v-for="(item,index) in addPanelData.custom" :key="index">
                            <trash-can @click.stop="deleteRow(index)"/>
                            <div class="addRowLeftNorA">
                                <input v-model="item.key" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                            <div class="addRowRightNorA">
                                <input v-model="item.value" maxlength="40" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA" v-if="addPanelData.custom.length<9">
                            <button class="buttonNorA" @click.stop="addRowNew()">向上添加一行</button>
                        </div>
                    </div>
                    <div class="addPanelButtonA">
                        <button class="buttonA" @click.stop="cancelAddPanel()">取消添加</button>
                        <button class="buttonA" @click.stop="submitAdd()">确定添加</button>
                    </div>
                </div>
            </div>
            <div class="addPanelShowB" v-if="vertical">
                <div class="addPanelTopB">
                    <div class="addPanelLeftTxtB">预览 · Preview</div>
                    <div class="addPanelLeftCardA">
                        <banana-card-item-preview
                                :game_name="addPanelData.game_name"
                                :qq_id="addPanelData.qq_id"
                                :game_id="addPanelData.game_id"
                                :nickname="addPanelData.nickname"
                                :remarks="addPanelData.remarks"
                                :custom="addPanelData.custom"
                        />
                    </div>
                </div>
                <div class="addPanelBottomB">
                    <div class="addPanelContentA">
                        <div class="addPanelRowA">
                            <div class="addRowLeftA" style="color: #ff5663">
                                游戏名称：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.game_name" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA">
                            <div class="addRowLeftA" style="color: #ff5663">
                                游戏昵称：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.nickname" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA">
                            <div class="addRowLeftA" style="color: #ff5663">
                                玩家ＩＤ：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.game_id" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA">
                            <div class="addRowLeftA">
                                备注信息：
                            </div>
                            <div class="addRowRightA">
                                <input v-model="addPanelData.remarks" maxlength="40" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA" v-for="(item,index) in addPanelData.custom" :key="index">
                            <trash-can-b @click.stop="deleteRow(index)"/>
                            <div class="addRowLeftNorA">
                                <input v-model="item.key" maxlength="20" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                            <div class="addRowRightNorA">
                                <input v-model="item.value" maxlength="40" @focusin="onFocusMode()" @focusout="noFocusMode()"/>
                            </div>
                        </div>
                        <div class="addPanelRowA" v-if="addPanelData.custom.length<9">
                            <button class="buttonNorB" @click.stop="addRowNew()">向上添加一行</button>
                        </div>
                    </div>
                    <div class="addPanelButtonA">
                        <button class="buttonB" @click.stop="cancelAddPanel()">取消添加</button>
                        <button class="buttonB" @click.stop="submitAdd()">确定添加</button>
                    </div>
                </div>
            </div>
            <div class="addPanelShowCloseB" v-if="vertical" @touchend.stop="addPanelShow=false"/>
        </div>
    </div>
    <banana-search-result :result="searchData" @deleteCard="deleteCard"/>
    <div class="toTopA" v-show="toTopShow" @click.stop="toTheTop()">
        <span>➔</span>
    </div>
    <pomelo-confirm :view="firmView"  :message="firmMessage" :plan="firmPlan" @confirm="handleConfirm"/>
</template>

<script>
    import BananaCardItemVoluntarily from "./BananaCardItemVoluntarily";
    import BananaCardItem from "./BananaCardItem";
    import BananaCardItemPreview from "./BananaCardItemPreview";
    import trashCan from "./svgValidIcons/trashCan";
    import trashCanB from "./svgValidIcons/trashCanB";
    import dropdownButton from "./svgValidIcons/dropdownButton";
    import BananaSearchResult from "./BananaSearchResult";
    import PomeloConfirm from "./PomeloConfirm";
    import axios from 'axios';
    import {Base64} from 'js-base64';

    export default {
    name: "LayerDataSheet",
    components:{
        BananaCardItem,
        BananaCardItemPreview,
        trashCan,
        trashCanB,
        dropdownButton,
        BananaSearchResult,
        BananaCardItemVoluntarily,
        PomeloConfirm,
    },
    mounted() {
        this.getData('bh3');//默认拉取表格
        let QQ=this.$store.state.userData.qq_id;
        if(QQ===null){
            this.addPanelData.qq_id='none';
        }else {
            this.addPanelData.qq_id=QQ;
        }
        this.listenScroll();
    },
    data(){
    return{
        firmView:false,
        firmPlan:{},
        firmMessage:'',
        toTopShow:false,
        attentionTextShow:false,
        addTime:new Date().getTime(),
        searchTime:new Date().getTime(),
        addPanelData:{
            game_name:'',
            qq_id:'',
            game_id:'',
            nickname:'',
            remarks:'',
            custom:[]
        },
        addPanelShow:false,
        sheets:['bh3','ys','pcr','voluntarily'],
        searchText:'',
        searchTextDefault:'点击左侧切换搜素类型',
        selectSheet:'bh3',//选中的表
        sheetList:[
            {name:'崩坏三', sheetName:'bh3'},
            {name:'公主连结', sheetName:'pcr'},
            {name:'原神', sheetName:'ys'},
            {name:'最近添加', sheetName:'latest'},
            {name:'自行添加', sheetName:'voluntarily'}
        ],
        bh3CacheData:[],
        pcrCacheData:[],
        ysCacheData:[],
        sheetData:[//表格数据
            {
                insert_time:'0000-00-00 00:00:00',
                serial_number:1,
                qq_id:'none',
                group_nickname:'加载中...',
                game_id:'加载中...',
                game_nickname:'加载中...',
                habit_name:'加载中...',
                remarks:'加载中...'
            }
        ],
        page:1,
        pageCount:0,//页数
        pageSize:100,//每页数量
        searchData:{
            keyword:'',
            content:[]
        },
        searchTypeListView:false,
        searchTypeList:['QQ','UID','昵称'],
        searchType:'QQ',//'QQ' 'UID' '昵称'
    }
    },
    methods:{
        attentionTextClose(){
            setTimeout(()=>this.attentionTextShow=false,2);
        },
        attentionTextOpen(){
            setTimeout(()=>this.attentionTextShow=true,2);
        },
        deleteCard(number){//删除资料
            this.firmPlan={method:'deleteCard',value:number};
            this.firmMessage='即将删除此条数据，是否要继续？';
            this.firmView=true;//呼出确认菜单
        },
        handleConfirm(plan){
            if(!plan.state){//取消执行
                this.firmView=false;//关闭确认菜单
                return false;
            }
            this.firmView=false;//关闭确认菜单
            let method=plan.method;
            let value=plan.value;
            switch(method){
                case 'deleteCard':{//删除此图层与其中的元素
                    const params = new URLSearchParams();// 使用 URLSearchParams 构造表单数据
                    params.append('serial_number',value);
                    axios.post('../php/api/public/int_delete_voluntarily_data.php',params,
                        {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                        .then(response => {
                            if(response.data==='ok'){
                                this.$store.commit('setCoLogMessage',{text:'删除成功。',from:'internal:LayerDataSheet',type:'notice'});
                                this.getData('voluntarily',this.page);
                            }else if(response.data==='fail'){
                                this.$store.commit('setCoLogMessage',{text:'删除失败，failed。',from:'internal:LayerDataSheet',type:'error'});
                            }else {
                                this.$store.commit('setCoLogMessage',{text:'删除失败。',from:'internal:LayerDataSheet',type:'error'});
                            }
                        })
                        .catch(function (error){ // 请求失败处理
                            console.log(error);
                        });
                    break;
                }
            }
        },
        listenScroll(){
            window.addEventListener('scroll', ()=>{
                this.toTopShow = window.scrollY > 200;
            });
        },
        toTheTop(){
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
        },
        pageBtClass(num){//页数的class
            if(num==='···')return 'pageNumberElA';
            if(num===this.page)return 'pageNumberSelectA';
            return 'pageNumberA';
        },
        pageBtClick(num){//切换页数
            if(typeof num!=='number')return;
            this.page=num;
        },
        pageBtUp(){//上一页
            if(this.page<=1)return;
            this.page--;
        },
        pageBtDown(){//下一页
            if(this.page>=this.pageCount)return;
            this.page++;
        },
        getData(name,page){
            if(!this.sheets.includes(name))return false;
            let param='';
            let page_='';
            if(name==='bh3' && this.bh3CacheData.length!==0){
                this.sheetData=JSON.parse(JSON.stringify(this.bh3CacheData));
                return true;
            }
            else if(name==='pcr' && this.pcrCacheData.length!==0){
                this.sheetData=JSON.parse(JSON.stringify(this.pcrCacheData));
                return true;
            }
            else if(name==='ys' && this.ysCacheData.length!==0){
                this.sheetData=JSON.parse(JSON.stringify(this.ysCacheData));
                return true;
            }else if(name==='voluntarily'){
                param='&oder_by=serial_des';
                if(!isNaN(page)){
                    page=page-1;
                    page_='&page_number='+page;
                }
            }
            axios.get('../php/api/public/int_mysql_get_data_sheet.php?game_name='+name+param+page_)
                .then(response => {
                    let DATA=response.data.data;
                    let total=response.data.total;
                    this.pageCount=Math.ceil(total/this.pageSize);
                    let ref=[];
                    if(Array.isArray(DATA)){
                        let len=DATA.length;
                        for(let i=0;i<len;i++){
                            if(name!=='voluntarily'){
                                ref.push({
                                    insert_time:DATA[i][0],
                                    serial_number:parseInt(DATA[i][1]),
                                    qq_id:DATA[i][2],
                                    group_nickname:DATA[i][3],
                                    game_id:DATA[i][4],
                                    game_nickname:DATA[i][5],
                                    habit_name:DATA[i][6],
                                    remarks:DATA[i][7]
                                });
                            }
                            else {
                                let index_number=i;
                                let anonymous=DATA[i][0];
                                if(anonymous!=='T')anonymous='F';
                                let insert_time=DATA[i][1];
                                if(typeof insert_time!=='string')insert_time='0000-00-00 00:00:00';
                                let serial_number=parseInt(DATA[i][2]);
                                if(isNaN(serial_number))serial_number=0;
                                let qq_id=DATA[i][3];
                                if(/^\d{5,12}$/.test(qq_id)===false)qq_id='none';
                                let game_id=DATA[i][4];
                                if(/^\d+$/.test(game_id)===false)game_id='none';
                                let game_nickname=DATA[i][5];
                                if(typeof game_nickname!=='string')game_nickname='none';
                                let game_name=DATA[i][6];
                                if(typeof game_name!=='string')game_name='none';
                                let custom=DATA[i][7];
                                try{
                                    if(custom===null || custom===''){
                                        custom=[];
                                    }else {
                                        custom=JSON.parse(window.atob(custom));
                                    }
                                    if(!Array.isArray(custom))custom=[];
                                }catch(e){console.log(e);}
                                let remarks=DATA[i][8];
                                if(typeof remarks!=='string')remarks='none';
                                ref.push({
                                    anonymous,
                                    insert_time,
                                    serial_number,
                                    qq_id,
                                    game_id,
                                    game_nickname,
                                    game_name,
                                    custom,
                                    remarks,
                                    index_number
                                });
                            }
                        }
                    }
                    if(ref.length!==0){
                        this.sheetData.length=0;//清空数据
                        this.sheetData = ref;
                        if(name==='bh3'){
                            this.bh3CacheData = ref;
                        }
                        else if(name==='pcr'){
                            this.pcrCacheData = ref;
                        }
                        else if(name==='ys'){
                            this.ysCacheData = ref;
                        }
                    }
                })
                .catch(function (error){ // 请求失败处理
                console.log(error);
            });
        },
        getNewData(){
            axios.get('../php/api/public/int_mysql_select_new_data.php?sheet_name=all')
                .then(response => {
                    let DATA=response.data[0];
                    this.sheetData.length=0;//清空数据
                    this.sheetData.push(
                        {
                            insert_time:DATA.bh3_insert_time,
                            serial_number:1,
                            qq_id:DATA.bh3_qq_id,
                            group_nickname:DATA.bh3_group_nickname,
                            game_id:DATA.bh3_game_id,
                            game_nickname:DATA.bh3_game_nickname,
                            habit_name:DATA.bh3_habit_name,
                            remarks:DATA.bh3_remarks
                        },
                        {
                            insert_time:DATA.pcr_insert_time,
                            serial_number:2,
                            qq_id:DATA.pcr_qq_id,
                            group_nickname:DATA.pcr_group_nickname,
                            game_id:DATA.pcr_game_id,
                            game_nickname:DATA.pcr_game_nickname,
                            habit_name:DATA.pcr_habit_name,
                            remarks:DATA.pcr_remarks
                        },
                        {
                            insert_time:DATA.ys_insert_time,
                            serial_number:3,
                            qq_id:DATA.ys_qq_id,
                            group_nickname:DATA.ys_group_nickname,
                            game_id:DATA.ys_game_id,
                            game_nickname:DATA.ys_game_nickname,
                            habit_name:DATA.ys_habit_name,
                            remarks:DATA.ys_remarks
                        }
                    );
                })
                .catch(function (error){ // 请求失败处理
                    console.log(error);
                });
        },
        search(){
            let keyword=this.searchText.trim();
            let now=new Date().getTime();
            if(this.searchData.keyword===keyword){//显示上一次搜索结果
                this.searchData.keyword='0'+this.searchData.keyword;
                this.searchData.keyword=this.searchData.keyword.slice(1);//强制刷新
                return false;
            }
            if(now - this.searchTime<=2500){//避免频繁搜索
                this.$store.commit('setCoLogMessage',{text:'搜素过于频繁',from:'internal:LayerDataSheet',type:'warn'});
                return false;
            }
            let regex = /[^\u4E00-\u9FFF0-9A-Za-z]/;//正则表达式匹配汉字、字母（包括大小写）、数字以外的字符
            if(regex.test(keyword)){//匹配到规定之外的字符
                this.$store.commit('setCoLogMessage',{text:'只能输入汉字、字母、数字以内的字符',from:'internal:LayerDataSheet',type:'warn'});
                return false;
            }
            if(keyword.length>20){
                this.$store.commit('setCoLogMessage',{text:'最多只能输入20个字',from:'internal:LayerDataSheet',type:'warn'});
                return false;
            }
            this.searchTime=now;//重置最后搜索的时间
            this.searchData.keyword=keyword;//更新搜索关键词
            this.searchData.content.length=0;//清空上一次搜索的结果
            let parameter='';
            let property='';
            if(this.searchType==='QQ'){
                parameter='search_qq='+keyword;
                property='qq_id';
            }
            else if(this.searchType==='UID'){
                parameter='search_uid='+keyword;
                property='game_id';
            }
            else if(this.searchType==='昵称'){
                parameter='search_nickname='+keyword;
                property='game_nickname';
            }
            else {
                parameter='search='+keyword;
                property='qq_id';
            }
            if(this.bh3CacheData.length===0){//如果本地没有加载bh3的数据则向服务器搜索
                axios.get('../php/api/public/int_mysql_get_data_sheet.php?game_name=bh3&'+parameter)
                    .then(response => {
                        let DATA=response.data.data;
                        if(Array.isArray(DATA)){
                            let len=DATA.length;
                            for(let i=0;i<len;i++){
                                let obj={
                                    sheet_name:'bh3',
                                    insert_time:DATA[i][0],
                                    serial_number:parseInt(DATA[i][1]),
                                    qq_id:DATA[i][2],
                                    group_nickname:DATA[i][3],
                                    game_id:DATA[i][4],
                                    game_nickname:DATA[i][5],
                                    habit_name:DATA[i][6],
                                    remarks:DATA[i][7]
                                };
                                this.searchData.content.push(obj);
                            }
                        }
                    })
                    .catch(function (error){ // 请求失败处理
                        console.log(error);
                    });
            }
            else{//本地进行搜索
                let length=this.bh3CacheData.length;
                for(let i=0;i<length;i++){
                    if(this.searchType!=='昵称'){//QQ UID使用全等匹配
                        if(this.bh3CacheData[i][property]!==keyword)continue;
                        let obj=this.bh3CacheData[i];
                        obj['sheet_name']='bh3';
                        this.searchData.content.push(obj);
                    }else {//昵称使用相似匹配
                        if(this.bh3CacheData[i][property].includes(keyword) || this.bh3CacheData[i]['group_nickname'].includes(keyword)){
                            let obj=this.bh3CacheData[i];
                            obj['sheet_name']='bh3';
                            this.searchData.content.push(obj);
                        }
                    }
                }
            }

            if(this.pcrCacheData.length===0){//如果本地没有加载pcr的数据则向服务器搜索
                axios.get('../php/api/public/int_mysql_get_data_sheet.php?game_name=pcr&'+parameter)
                    .then(response => {
                        let DATA=response.data.data;
                        if(Array.isArray(DATA)){
                            let len=DATA.length;
                            for(let i=0;i<len;i++){
                                let obj={
                                    sheet_name:'pcr',
                                    insert_time:DATA[i][0],
                                    serial_number:parseInt(DATA[i][1]),
                                    qq_id:DATA[i][2],
                                    group_nickname:DATA[i][3],
                                    game_id:DATA[i][4],
                                    game_nickname:DATA[i][5],
                                    habit_name:DATA[i][6],
                                    remarks:DATA[i][7]
                                };
                                this.searchData.content.push(obj);
                            }
                        }
                    })
                    .catch(function (error){ // 请求失败处理
                        console.log(error);
                    });
            }
            else{//本地进行搜索
                let length=this.pcrCacheData.length;
                for(let i=0;i<length;i++){
                    if(this.searchType!=='昵称'){//QQ UID使用全等匹配
                        if(this.pcrCacheData[i][property]!==keyword)continue;
                        let obj=this.pcrCacheData[i];
                        obj['sheet_name']='pcr';
                        this.searchData.content.push(obj);
                    }else {//昵称使用相似匹配
                        if(this.pcrCacheData[i][property].includes(keyword) || this.pcrCacheData[i]['group_nickname'].includes(keyword)){
                            let obj=this.pcrCacheData[i];
                            obj['sheet_name']='pcr';
                            this.searchData.content.push(obj);
                        }
                    }
                }
            }

            if(this.ysCacheData.length===0){//如果本地没有加载ys的数据则向服务器搜索
                axios.get('../php/api/public/int_mysql_get_data_sheet.php?game_name=ys&'+parameter)
                    .then(response => {
                        let DATA=response.data.data;
                        if(Array.isArray(DATA)){
                            let len=DATA.length;
                            for(let i=0;i<len;i++){
                                let obj={
                                    sheet_name:'ys',
                                    insert_time:DATA[i][0],
                                    serial_number:parseInt(DATA[i][1]),
                                    qq_id:DATA[i][2],
                                    group_nickname:DATA[i][3],
                                    game_id:DATA[i][4],
                                    game_nickname:DATA[i][5],
                                    habit_name:DATA[i][6],
                                    remarks:DATA[i][7]
                                };
                                this.searchData.content.push(obj);
                            }
                        }
                    })
                    .catch(function (error){ // 请求失败处理
                        console.log(error);
                    });
            }
            else{//本地进行搜索
                let length=this.ysCacheData.length;
                for(let i=0;i<length;i++){
                    if(this.searchType!=='昵称'){//QQ UID使用全等匹配
                        if(this.ysCacheData[i][property]!==keyword)continue;
                        let obj=this.ysCacheData[i];
                        obj['sheet_name']='ys';
                        this.searchData.content.push(obj);
                    }else {//昵称使用相似匹配
                        if(this.ysCacheData[i][property].includes(keyword) || this.ysCacheData[i]['group_nickname'].includes(keyword)){
                            let obj=this.ysCacheData[i];
                            obj['sheet_name']='ys';
                            this.searchData.content.push(obj);
                        }
                    }
                }
            }

            axios.get('../php/api/public/int_mysql_get_data_sheet.php?game_name=voluntarily&'+parameter)
                .then(response => {
                    let DATA=response.data.data;
                    if(Array.isArray(DATA)){
                        let len=DATA.length;
                        for(let i=0;i<len;i++){
                            let custom=DATA[i][7];
                            try{
                                if(custom===null || custom===''){
                                    custom=[];
                                }else {
                                    custom=JSON.parse(window.atob(custom));
                                }
                                if(!Array.isArray(custom))custom=[];
                            }catch(e){console.log(e);}
                            let obj={
                                sheet_name:'voluntarily',
                                anonymous:DATA[i][0],
                                insert_time:DATA[i][1],
                                serial_number:parseInt(DATA[i][2]),
                                qq_id:DATA[i][3],
                                game_id:DATA[i][4],
                                game_nickname:DATA[i][5],
                                game_name:DATA[i][6],
                                custom:custom,
                                remarks:DATA[i][8]
                            };
                            this.searchData.content.push(obj);
                        }
                    }
                })
                .catch(function (error){ // 请求失败处理
                    console.log(error);
                });
        },
        submitAdd(){
            if(this.qq_id===null){
                this.$store.commit('setCoLogMessage',{text:'请您登录后再添加。',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            let now=new Date().getTime();
            if(now - this.addTime<=2500){//避免频繁
                this.$store.commit('setCoLogMessage',{text:'“添加” CD 冷却中...',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(/^\d{5,20}$/.test(this.addPanelData.game_id)===false){
                this.$store.commit('setCoLogMessage',{text:'玩家ID为5-20位数字，请修改后重试。',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(this.addPanelData.game_name.length<1){
                this.$store.commit('setCoLogMessage',{text:'游戏名称不能为空',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(this.addPanelData.nickname.length<1){
                this.$store.commit('setCoLogMessage',{text:'游戏昵称不能为空',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(/[='"`#\\/]/.test(this.addPanelData.nickname)){
                this.addPanelData.nickname=this.addPanelData.nickname.replace(/[='"`#\\/]/);
            }
            if(this.addPanelData.nickname.length>20){
                this.$store.commit('setCoLogMessage',{text:'游戏昵称最长为20个字',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(/[='"`#\\/]/.test(this.addPanelData.game_name)){
                this.addPanelData.game_name=this.addPanelData.game_name.replace(/[='"`#\\/]/);
            }
            if(this.addPanelData.game_name.length>20){
                this.$store.commit('setCoLogMessage',{text:'游戏名称最长为20个字',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(/[='"`#\\/]/.test(this.addPanelData.remarks)){
                this.addPanelData.remarks=this.addPanelData.remarks.replace(/[='"`#\\/]/);
            }
            if(this.addPanelData.remarks.length>40){
                this.$store.commit('setCoLogMessage',{text:'备注最长为40个字',from:'internal:LayerDataSheet',type:'warn'});return false;
            }
            if(!Array.isArray(this.addPanelData.custom)){return false;}
            for(let i=0;i<this.addPanelData.custom.length;i++){
                if(typeof this.addPanelData.custom[i]!=='object'){return false;}
            }
            let obj={
                game_id:this.addPanelData.game_id,
                game_nickname:this.addPanelData.nickname,
                game_name:this.addPanelData.game_name,
                remarks:this.addPanelData.remarks,
                custom:Base64.encode(JSON.stringify(this.addPanelData.custom))
            };
            this.addTime=now;//重置最后添加的时间
            const params = new URLSearchParams();// 使用 URLSearchParams 构造表单数据
            params.append('game_id',obj.game_id);
            params.append('game_nickname',obj.game_nickname);
            params.append('game_name',obj.game_name);
            params.append('remarks',obj.remarks);
            params.append('custom',obj.custom);
            axios.post('../php/api/public/int_upload_voluntarily_data.php',params,
                {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                .then(response => {
                    if(response.data==='ok'){
                        this.$store.commit('setCoLogMessage',{text:'添加成功！',from:'internal:LayerDataSheet',type:'notice'});
                        this.addPanelShow=false;//关闭面板
                        this.clearAddPanelData();//清理缓存
                        this.getData('voluntarily');//重新获取数据
                    }else if(response.data==='fail'){
                        this.$store.commit('setCoLogMessage',{text:'添加失败，failed。',from:'internal:LayerDataSheet',type:'error'});
                    }else {
                        this.$store.commit('setCoLogMessage',{text:'添加失败。',from:'internal:LayerDataSheet',type:'error'});
                    }
                })
                .catch(function (error){ // 请求失败处理
                    console.log(error);
                });
        },
        clearAddPanelData(){
            this.addPanelData.game_name='';
            this.addPanelData.game_id='';
            this.addPanelData.remarks='';
            this.addPanelData.custom.length=0;
        },
        openBh3Page(){
            window.open('https://www.bh3.com', '_blank');
        },
        openYsPage(){
            window.open('https://ys.mihoyo.com', '_blank');
        },
        openPcrPage(){
            window.open('https://priconne-redive.jp', '_blank');
        },
        onFocusMode(){//聚焦模式
            this.$store.state.mapConfig.inputFocusStatus=true;
        },
        noFocusMode(){//非聚焦模式
            this.$store.state.mapConfig.inputFocusStatus=false;
        },
        changeSheet(name){
            if(name===this.selectSheet)return false;
            this.selectSheet=name;
            if(name==='latest'){
                this.getNewData();
            }else if(name==='bh3'){
                this.getData('bh3');
            }else if(name==='pcr'){
                this.getData('pcr');
            }else if(name==='ys'){
                this.getData('ys');
            }else if(name==='voluntarily'){
                this.getData('voluntarily');
            }
        },
        addRowNew(){//在自定义的属性中新增一行
          if(this.addPanelData.custom.length>=9){
              this.$store.commit('setCoLogMessage',{text:'最多可以添加９个自定义属性',from:'internal:LayerDataSheet',type:'warn'});
              return false;
          }
          this.addPanelData.custom.push({
              key:'属性',
              value:'值'
          });
        },
        deleteRow(index){//删除自定义的属性的某一行
            this.addPanelData.custom.splice(index,1);
        },
        touchOpenAddPanel(){
            setTimeout(
                ()=>{
                    if(this.qq_id===null){
                        this.$store.commit('setCoLogMessage',{text:'请您登录后再添加。',from:'internal:LayerDataSheet',type:'warn'});
                        this.$store.commit('setCoLoginBoardView',true);
                        return false;
                    }
                    this.addPanelShow=true;
                }
                ,4);
        },
        openAddPanel(){
            if(this.$store.state.pageConfig.isMobile){return false;}
            if(this.qq_id===null){
                this.$store.commit('setCoLogMessage',{text:'请您登录后再添加。',from:'internal:LayerDataSheet',type:'warn'});
                this.$store.commit('setCoLoginBoardView',true);
                return false;
            }
            this.addPanelShow=true;
        },
        cancelAddPanel(){
            setTimeout(
                ()=>{
                    this.addPanelShow=false;
                    this.clearAddPanelData();
                }
            ,2);
        },
    },
    computed:{
        pageList(){
            if(this.pageCount<=1){
                return [];
            }else if(this.pageCount>1 && this.pageCount<=7){
                return Array.from({ length: this.pageCount }, (_, i) => i + 1);
            }else{
                if(this.page<=3){
                    return [1, 2 , 3 , 4 , 5 ,'···',this.pageCount];
                }
                if(this.page>=this.pageCount-5){
                    return [this.pageCount-5, this.pageCount-4 , this.pageCount-3 , this.pageCount-2 , this.pageCount-1 ,this.pageCount];
                }
                return [this.page-2, this.page-1, this.page,this.page+1,this.page+2,'···',this.pageCount];
            }
        },
        vertical(){
            return this.$store.state.pageConfig.vertical;
        },
        qq_id(){
            return this.$store.state.userData.qq_id;
        }
    },
    watch:{
        qq_id:{
            handler(newValue){
                if(newValue===null){
                    this.addPanelData.qq_id='none';
                }else {
                    this.addPanelData.qq_id=newValue;
                }
            }
        },
        page:{
            handler(newValue){
                this.getData('voluntarily',newValue);
            }
        }
    }
}
</script>

<style scoped>
/*AB css start*/
/*横屏*/
.sheetVoluntarilyA{
    width: 100%;
    height: auto;
}
.sheetVoluntarilyB{
    width: 100%;
    height: auto;
}
.toTopA{
    width: 50px;
    height: 50px;
    transform: scale(1) rotate(-90deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 70;
    right: 100px;
    bottom: 100px;
    font-weight: 800;
    font-size: 30px;
    border-radius: 50px;
    box-shadow: 0px 0px 6px rgba(0,0,0,0.5);
    color:rgba(0,0,0,0.5);
    transition: 0.3s;
    cursor: pointer;
    user-select: none;
}
.toTopA:hover{
    box-shadow: 0px 0px 6px rgba(0,0,0,0.9);
    color:rgba(0,0,0,0.7);
    transform: scale(1.2) rotate(-90deg) rotateX(180deg);
}
.toTopA span{
    transform: translateY(-1px);
}
.pageNavigateA{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    overflow: hidden;
    margin-bottom: 40px;
}
.pageNumberElA{/*省略符号*/
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: #494c50;
    cursor: default;
}
.pageNumberA{/*普通的页面按钮*/
    user-select: none;
    min-width: calc(30px - 2px);
    height: calc(30px - 2px);
    padding: 0px 5px;
    font-size: 14px;
    font-weight: 400;
    background: #ffffff;
    color: #494c50;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px #d7dde4 solid;
    margin: 0px 4px;
    cursor: pointer;
}
.pageNumberSelectA{/*当前选中的页面按钮*/
    user-select: none;
    min-width: calc(30px - 2px);
    height: calc(30px - 2px);
    padding: 0px 5px;
    font-size: 14px;
    font-weight: 400;
    background: #00a1d6;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px #d7dde4 solid;
    margin: 0px 4px;
    cursor: pointer;
}
.listOutA{
    width: calc(100% - 10px);
    padding: 5px 0px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    border-radius: 5px;
}
.listOutB{
    width: 60px;
    padding: 5px 0px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    box-shadow:0px 0px 6px rgba(0,0,0,0.25);
    border-radius: 5px;
    position: absolute;
    z-index: 75;
    top: 0px;

}
.pickedListA{
    background: #5d6eeb;
    color: white;
}
.listItemA{
    width: calc(100%);
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}
.listItemB{
    width: calc(100%);
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}
.latestDesA{
    width: 100%;
    height: 50px;
    background: rgba(255,255,255,1);
    box-shadow: inset 0px 0px 8px rgba(0,0,0,0.08);
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.latestDesB{
    width: calc(100% - 20px);
    height: 35px;
    background: rgba(255,255,255,1);
    box-shadow: inset 0px 0px 8px rgba(0,0,0,0.04);
    border-radius: 50px;
    margin:10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.anonymousA{
    width: 40px;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #ff6f70;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
}
.attentionTextA{
    width: 580px;
    height: 200px;
    padding: 5px;
    font-size: 14px;
    background: #333232;
    color: white;
    border-radius: 10px;
    position: absolute;
    transform: translateX(-100px) translateY(80px);
    z-index: 70;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: start;
    box-shadow: 0px 0px 6px white;
    pointer-events: none;
}
.attentionTextB{
    width: calc(100% - 42px - 42px);
    height: auto;
    padding: 10px;
    font-size: 14px;
    background: #333232;
    color: white;
    border-radius: 10px;
    position: fixed;
    left: calc(42px + 10px);
    top: calc(50% - 132px);
    z-index: 70;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: start;
    box-shadow: 0px 0px 6px white;
}
.voluntarilyDesCloseB{
    width: calc(100% - 42px);
    height: 100%;
    position: fixed;
    z-index: 60;
    left: 42px;
    top: 0;
    animation:toBlur 0.5s forwards;
}
@keyframes toBlur{
    from{
        backdrop-filter: blur(0px);
    }
    to{
        backdrop-filter: blur(2px);
    }
}
.attentionA{
    width: auto;
    height: auto;
    font-size: 13px;
    color: white;
    background: #59aaff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0px 2px;
    padding: 2px 4px;
    cursor: pointer;
}
.addRowLeftNorA{
    width: calc(35% - 40px);
    margin-left: 5px;
}
.addRowLeftNorA input{
    width: calc(100% - 10px);
    margin: 0;
    padding: 4px;
}
.addRowRightNorA{
    width: calc(65% - 3px);
    margin-left: 10px;
}
.addRowRightNorA input{
    width: calc(100% - 10px);
    min-width: 200px;
    margin: 0;
    padding: 4px;
}
.buttonNorA{
    cursor: pointer;
    -webkit-appearance: none;
    width: 66.9%;
    border-radius: 24px;
    text-align: center;
    padding: 5px 20px;
    background-color: #7dc5ff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    border: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    outline: 0;
    letter-spacing: 2.5px;
}
.buttonNorA:hover{
    background: #289ffe;
}
.buttonNorA:active{
    background: #ffffff;
    color: #000000;
}
.buttonNorB{
    cursor: pointer;
    -webkit-appearance: none;
    width: 66.9%;
    border-radius: 24px;
    text-align: center;
    padding: 5px 20px;
    background-color: #7dc5ff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    border: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    outline: 0;
    letter-spacing: 2.5px;
}
.buttonNorB:active{
    background: #1187ff;
    color: #fff;
}
.buttonA{
    cursor: pointer;
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 5px 20px;
    background-color: #7dc5ff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    border: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    outline: 0;
}
.buttonA:hover{
    background: #289ffe;
}
.buttonA:active{
    background: #ffffff;
    color: #000000;
}
.buttonB{
    cursor: pointer;
    -webkit-appearance: none;
    width: auto;
    min-width: 100px;
    border-radius: 24px;
    text-align: center;
    padding: 5px 20px;
    background-color: #7dc5ff;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    -webkit-box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    box-shadow: 0 2px 6px -1px rgba(0,0,0,.13);
    border: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    outline: 0;
}
.buttonB:active{
    background: #289ffe;
    color: #fff;
}
.addPanelShowA{
    width: calc(970px - 20px);
    height: calc(600px - 20px);
    padding: 10px;
    background: rgb(255,255,255);
    border-radius: 10px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    position: fixed;
    z-index: 60;
    top: calc(50% - 300px);
    left: calc(50% - 485px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addPanelShowCloseB{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 60;
    left: 0;
    top: 0;
    animation:toBlur 0.5s forwards;
}
@keyframes toBlur{
    from{
        backdrop-filter: blur(0px);
    }
    to{
        backdrop-filter: blur(2px);
    }
}
.addPanelTopB{
    width: 100%;
    height: 160px;
}
.addPanelBottomB{
    width: 100%;
    height: calc(100% - 160px);
}
.addPanelShowB{
    width: 88%;
    height: 90%;
    padding: 7px;
    background: rgb(255,255,255);
    border-radius: 10px;
    box-shadow:0px 0px 6px rgba(0,0,0,0.19);
    position: fixed;
    z-index: 65;
    top: calc(5% - 7px);
    left: calc(6% - 7px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addPanelLeftA{
    width: calc(340px - 20px);
    height: calc(100% - 10px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.addPanelLeftTxtB{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addPanelLeftTxtA{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addPanelLeftCardA{
    width: 100%;
    height: calc(100% - 80px - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.addPanelLeftRuleA{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.addPanelRightA{
    width: calc(100% - 340px);
    height: 100%;
}
.addPanelLeftRowA{
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0px;
    padding: 0px 10px;
}
.addPanelContentA{
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
}
.addPanelRowA{
    width: 100%;
    height: 40px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addRowLeftA{
    width: calc(35% - 7px);
    min-width: 60px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addRowRightA{
    width: 65%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addRowRightA input{
    height: 16px;
    font-size: 14px;
    width: calc(100% - 10px);
    min-width: 200px;
    margin: 0;
    padding: 4px;
}
.addPanelButtonA{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.sousuoA{
    width: 36px;
    height: 36px;
}
.searchTypeA{
    width: 66px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.searchTypeA span{
    margin-right: 4px;
}
.searchTypeB{
    width: 66px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.searchTypeB span{
    margin-right: 4px;
}
.searchTypeIconA{
    width: 10px;
    height: 100%;
}
.searchInputOutA{
    width: calc(100% - 50px);
    height: 30px;
    border-radius: 10px;
    border: 2px solid #7b7b7b;
    padding: 6px;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
}
.searchInputOutB{
    width: calc(100% - 50px);
    height: 24px;
    border-radius: 10px;
    border: 2px solid #7b7b7b;
    padding: 6px;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}
.searchInputA{
    outline: none;
    width: calc(100% - 50px);
    height: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
}
.searchInputB{
    outline: none;
    width: calc(100% - 50px);
    height: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}
.searchBoxA{
    width: 300px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.searchBoxB{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.LayerDataSheetA{
    width: 70%;
    height: auto;
}
.LayerDataSheetB{
    width: calc(100%);
    height: auto;
}
.switchOutA{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.switchOutB{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.level1B{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.switchBoxA{
    width: calc(100% - 100px - 20px - 300px);
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    overflow-x: auto;
    overflow-y: hidden;
}
.switchBoxA::-webkit-scrollbar {
    height: 4px !important;
}
.switchBoxB{
    width: calc(100% - 50px - 10px);
    height: 100%;
    padding: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #ffffff;
    overflow-x: auto;
    overflow-y: hidden;
}
.switchBoxB::-webkit-scrollbar {
    height: 2px !important;
}
.sheetNameA{
    width: auto;
    min-width: 50px;
    height: 40px;
    background: #fafaff;
    border-radius: 5px;
    margin: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    transition: 0.3s;
    color: rgba(0,0,0,0.8);
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
}
.sheetNameA:hover{
    background: #97caff;
    color: white;
    transform: scale(1.09);
}
.sheetNameB{
    width: auto;
    height: 34px;
    background: #fafaff;
    border-radius: 5px;
    margin: 2px 3.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    transition: 0.3s;
    color: rgba(0,0,0,0.8);
    box-shadow:0px 0px 4px rgba(0,0,0,0.20);
    font-size: 14px;
}
.pickedSheetA{
    background: #97caff;
    color: white;
}
.sheetIconOutA{
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sheetIconOutB{
    width: 50px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.sheetIconA{
    width: 55px;
    height: 55px;
    border-radius: 10px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    animation:sheetIconAnimationA 0.5s forwards;
}
.sheetIconB{
    width: 40px;
    height: 40px;
    border-radius: 7px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    animation:sheetIconAnimationA 0.5s forwards;
}
@keyframes sheetIconAnimationA{
    0%{
        opacity: 0;
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.sheetContentA{
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
}
.sheetContentB{
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    overflow-x: hidden;
    overflow-y: auto;
}
.voluntarilyDesA{
    width: 100%;
    min-height: 45px;
    background: #333232;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
}
.voluntarilyDesB{
    width: 95%;
    min-height: 40px;
    background: #333232;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    text-align: center;
    font-size: 14px;
    margin: 8px 2.5% 16px 2.5%;
}
.voluntarilySpanA{
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/*横屏*/


/*AB css start*/

/*通用css start*/
.LayerDataSheetOut{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.addPanelShow{
    width:0;
    height:0;
    position:fixed;
    z-index:60;
    left:0;
    top:0;
}
/*通用css end*/
</style>