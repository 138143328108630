//修改路由模式为history模式
import { createRouter, createWebHashHistory } from 'vue-router';
import SeparateContent from "@/components/SeparateContent";
const routes = [
    {
        path: '/',//路由重定向
        redirect: '/sheet',//重定向为sheet
    },
    {
        path: '/:pageName',
        name: 'SeparateContent',
        component: SeparateContent,
        props:true
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
