<template>
    <div class="BananaCardItemA" :style="BananaCardItemStyleA">
        <div class="cardItemA" @touchstart.stop="touchDetailsStart($event)" @touchend.stop="touchDetailsEnd($event)" @click.stop="touchOpen()">
            <div class="left">
                <img class="headImg" :src="url" alt="" :style="delay"/>
            </div>
            <div class="right" @mousemove.stop="hoverDetails($event)" @mouseleave.stop="view=false">
                <div class="row0">
                    <span class="anonymousA" v-if="anonymous==='T'">匿名</span>
                    <span class="userQqA" v-if="anonymous==='F'">QQ</span>
                    <span class="gamgeNameA">{{game_name}}</span>
                    <span class="removeBtA" v-if="user_qq_id===qq_id" @touchend.stop="touchRemoveCard()" @click.stop="removeCard()">删除</span>
                </div>
                <div class="row1">
                    {{game_nickname}}
                </div>
                <div class="row2">
                    QQ:{{qq_id}}&nbsp;UID:{{game_id}}
                </div>
                <div class="row2" v-if="remarks!==''">
                    {{remarks}}
                </div>
                <div class="row2" v-if="remarks===''">
                    {{insert_time}}
                </div>
            </div>
        </div>
    </div>
    <div class="hoverDetailsA" ref="hoverDetailsA" :style="hoverDetailsStyleA" v-show="view">
        <div class="row8">
            游戏名称：{{game_name}}
        </div>
        <div class="row8">
            游戏昵称：{{game_nickname}}
        </div>
        <div class="row8">
            ＱＱ账号：{{qq_id}}
        </div>
        <div class="row8">
            游戏账号：{{game_id}}
        </div>
        <div class="row8">
            插入时间：{{insert_time}}
        </div>
        <div class="row8">
            备注信息：{{remarks}}
        </div>
        <div class="row8" v-for="(item,index) in custom" :key="index">
            {{item.key}}：{{item.value}}
        </div>
    </div>
    <div class="hoverDetailsCloseB" v-if="this.viewB===true" @touchend.stop="touchClose()" @click.stop="touchClose()"/>
    <div class="hoverDetailsB" v-show="viewB">
        <div class="row8">
            游戏名称：{{game_name}}
        </div>
        <div class="row8">
            游戏昵称：{{game_nickname}}
        </div>
        <div class="row8">
            ＱＱ账号：{{qq_id}}
        </div>
        <div class="row8">
            游戏账号：{{game_id}}
        </div>
        <div class="row8">
            添加时间：{{insert_time}}
        </div>
        <div class="row8">
            备注信息：{{remarks}}
        </div>
        <div class="row8" v-for="(item,index) in custom" :key="index">
            {{item.key}}：{{item.value}}
        </div>
    </div>
</template>

<script>
export default {
    name: "BananaCardItemView",
    props:{
        width:{
            type:Number,
            default:320,
            required:false
        },
        anonymous:{
          type:String,
          default:'F',
          required:false,
        },
        custom:{
          type:Array,
          default:function(){
              return [];
          },
          required:false
        },
        insert_time:{
            type:String,
            default:'none',
            required:false
        },
        game_id:{
            type:String,
            default:'none',
            required:false
        },
        game_name:{
            type:String,
            default:'none',
            required:false
        },
        game_nickname:{
            type:String,
            default:'none',
            required:false
        },
        qq_id:{
            type:String,
            default:'none',
            required:false
        },
        remarks:{
            type:String,
            default:'',
            required:false
        },
        serial_number:{
            type:Number,
            default:0,
            required:false
        },
        index_number:{//数组下标某些动效需要用到
            type:Number,
            default:0,
            required:false
        },
        sheet_name:{
            type:String,
            default:'voluntarily',
            required:false
        }
    },
    data(){
        return{
            left: 0,
            top:0,
            view:false,
            viewB:false,
            touchStartX:null,
            touchStartY:null,
        }
    },
    mounted(){

    },
    computed:{
        vertical(){
            return this.$store.state.pageConfig.vertical;
        },
        user_qq_id(){
            return this.$store.state.userData.qq_id;
        },
        url(){
            return `http://q.qlogo.cn/g?b=qq&nk=${this.qq_id}&s=640&mType=friendlist`;
        },
        delay(){
            return `animation-delay: ${this.index_number*0.02}s;`;
        },
        hoverDetailsStyleA(){
            return {
                opacity:this.view?'1':'0',
                transform:`translate(${this.left}px, ${this.top}px)`
            }
        },
        BananaCardItemStyleA(){
            return{
                width: this.width+'px',
                animationDelay: `${this.index_number*0.02}s`,
            }
        },
    },
    methods:{
        touchDetailsStart(ev) {
            this.touchStartX = ev.targetTouches[0].clientX;
            this.touchStartY = ev.targetTouches[0].clientY;
        },
        touchDetailsEnd(ev) {
            let X = ev.changedTouches[0].clientX;
            let Y = ev.changedTouches[0].clientY;
            let tolerance = 2; // 容差范围±2px
            if (Math.abs(X - this.touchStartX) <= tolerance && Math.abs(Y - this.touchStartY) <= tolerance) {
                this.touchOpen();
            }
        },
        touchOpen(){
            setTimeout(()=>this.viewB=true,2);
        },
        touchClose(){
            setTimeout(()=>this.viewB=false,2);
        },
        touchRemoveCard(){
            this.$emit('deleteCard',this.serial_number);
        },
        removeCard(){
            if(this.$store.state.pageConfig.isMobile){return false;}
          this.$emit('deleteCard',this.serial_number);
        },
        hoverDetails(ev){//鼠标悬浮显示详细信息
            if(this.vertical)return false;
            let top=null;
            if(this.$refs.hoverDetailsA){
                let rec=this.$refs.hoverDetailsA.getBoundingClientRect();
                if(rec.height+rec.top>=window.innerHeight){
                    top=window.innerHeight-rec.height;
                }
            }
            this.left=ev.x+10;
            this.top=top===null?ev.y+10:top;
            if(this.view===false)this.view=true;
        }
    }
}
</script>

<style scoped>
/*AB css start*/
/*A横屏*/
.removeBtA{
    width: 36px;
    height: 20px;
    position: absolute;
    right: 0px;
    padding:0px 6px 0px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ffffff;
    background: #4c4c4c;
    border-radius: 4px;
    letter-spacing: 2px;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
}
.removeBtA:hover{
    color: #ffffff;
    background: #ff585a;
}
.anonymousA{
    width: 40px;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #ff6f70;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
}
.userQqA{
    cursor: default;
    width: 30px;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #00c229;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
}
.gamgeNameA{
    cursor: default;
    width: auto;
    max-width: 200px;
    overflow-x: hidden;
    height: 20px;
    font-size: 12px;
    color: white;
    background: #189eff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-right: 4px;
    padding: 0px 4px;
}
.hoverDetailsA{
    width: calc(225px - 20px);
    height: auto;
    padding: 10px;
    background: rgba(250,250,255,0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    position: fixed;
    pointer-events: none;
    z-index: 60;
    border-radius: 10px;
    color: #1b1b1b;
    left: 0;
    top: 0;
    opacity: 0;
}
.hoverDetailsCloseB{
    width: calc(100% - 42px);
    height: 100%;
    position: fixed;
    z-index: 60;
    left: 42px;
    top: 0;
    animation:toBlur 0.5s forwards;
}
@keyframes toBlur{
    from{
        backdrop-filter: blur(0px);
    }
    to{
        backdrop-filter: blur(2px);
    }
}
.hoverDetailsB{
    width: calc(250px - 20px);
    height: auto;
    max-height: 420px;
    overflow-y: auto;
    padding: 10px;
    background: rgba(250,250,255,0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    position: fixed;
    z-index: 65;
    border-radius: 10px;
    color: #1b1b1b;
    left: calc(50% - 105px);
    top: calc(50% - 150px);
    opacity: 1;
}
.BananaCardItemA{
    height: 80px;
    margin: 18px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: BananaCardItemAnimationA 0.3s forwards;
    opacity: 0;
}
@keyframes BananaCardItemAnimationA {
    0%{
        opacity: 0;
        transform: scale(2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.cardItemA{
    width: calc(320px - 10px);
    height: calc(80px - 10px);
    padding: 5px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/*AB css end*/
/*通用css start*/
.left{
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.right{
    width: calc(100% - 60px - 5px);
    padding-left: 5px;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.headImg{
    width: 90%;
    height: auto;
    border-radius: 5px;
    animation:headImgAnimation 0.5s forwards;
}
@keyframes headImgAnimation{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
.row0{
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    position: absolute;
    left: 0px;
    top: -20px;
    overflow: hidden;
}
.row1{
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    overflow: hidden;
}
.row2{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.row8{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
}
/*通用css end*/
</style>