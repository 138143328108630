<template>
    <div class="pomeloCM" v-if="view"><div class="pomeloCMA"><p class="pomeloCMP" v-text="message"></p><div class="pomeloCMD"><button class="pomeloCMB" @click.stop="confirm(true)">Yes</button><button class="pomeloCMB" @click.stop="confirm(false)">No</button></div></div></div>
</template>
<script>
    export default{
        name:'PomeloConfirm',
        props:{view:{type:Boolean,required:true},message:{type:String,required:true},
            plan:{type:Object,default:()=>{return {method:'none',value:null}},required:true}},//操作名称//携带参数
        methods:{confirm(sta){let re=JSON.parse(JSON.stringify(this.plan));re.state=sta;this.$emit('confirm',re);}}
    }
</script>
<style>
    .pomeloCM{position:fixed;z-index:1000;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;background-color:rgba(0,0,0,0.5);}
    .pomeloCMB{cursor:pointer;-webkit-appearance:none;width:auto;min-width:100px;border-radius:24px;text-align:center;padding:5px 10px;background-color:#7dc5ff;color:#fff;font-size:14px;font-weight:500;-webkit-box-shadow:0 2px 6px -1px rgba(0,0,0,.13);box-shadow:0 2px 6px -1px rgba(0,0,0,.13);border:none;-webkit-transition:all .3s ease;transition:all .3s ease;outline:0;}
    .pomeloCMB:hover{background:#289ffe;}
    .pomeloCMB:active{background:#ffffff;color:#000000;}
    .pomeloCMA{user-select:none;min-width:240px;background-color:white;padding:20px 25px;border-radius:5px;}
    .pomeloCMD{width:100%;height:auto;display:flex;flex-direction:row;justify-content:space-around;align-items:center;}
    .pomeloCMP{font-size:16px;margin:10px 0 16px 0;color:#5d5d5d;}
</style>