<template>
    <div class="LayerChat">
        <div class="des">
            如果您对ATSW感兴趣，欢迎您的加入，与我们一起交流！
        </div>
        <div class="des2">
            请您备注"来自官网"
        </div>
        <img :class="vertical?'imgB':'imgA'" src="../../static/img/other/qqq.jpg"/>
    </div>
</template>

<script>
    export default {
        name: "LayerChat",
        computed:{
            vertical(){
                return this.$store.state.pageConfig.vertical;
            },
        }
    }
</script>

<style scoped>
    .LayerChat{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .imgA{
        width: 400px;
        border-radius: 10px;
        box-shadow: 0 0 6px rgba(0,0,0,0.19);
    }
    .imgB{
        width: 300px;
        border-radius: 10px;
        box-shadow: 0 0 6px rgba(0,0,0,0.19);
    }
    .des{
        width: 80%;
        height: auto;
        margin: 20px 0px;
        font-size: 24px;
        font-weight: 800;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .des2{
        width: 80%;
        height: auto;
        margin: 20px 0px;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
</style>