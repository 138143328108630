<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16px" height="16px">
    <path fill-rule="evenodd"  fill="rgb(62, 128, 248)"
          d="M8.000,-0.000 C12.418,-0.000 16.000,3.582 16.000,8.000 C16.000,12.418 12.418,16.000 8.000,16.000 C3.582,16.000 -0.000,12.418 -0.000,8.000 C-0.000,3.582 3.582,-0.000 8.000,-0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M14.000,11.200 C10.000,11.200 6.000,11.200 2.000,11.200 C1.798,11.025 1.684,10.768 1.700,10.500 C1.714,10.267 1.824,10.051 2.000,9.900 C2.222,9.649 2.722,9.382 2.900,9.100 C3.534,8.095 3.563,6.933 3.600,5.700 C3.839,4.785 4.320,3.954 5.000,3.300 C5.817,2.513 6.872,2.127 8.000,2.000 C9.128,2.127 10.183,2.513 11.000,3.300 C11.680,3.954 12.161,4.785 12.400,5.700 C12.457,7.083 12.347,8.049 13.100,9.100 C13.224,9.273 13.858,9.740 14.000,9.900 C14.202,10.075 14.316,10.332 14.300,10.600 C14.286,10.833 14.176,11.049 14.000,11.200 ZM11.600,9.100 C10.868,8.058 10.951,7.080 11.000,5.700 C10.735,5.021 10.286,4.431 9.700,4.000 C9.199,3.632 8.616,3.393 8.000,3.300 C7.346,3.360 6.722,3.601 6.200,4.000 C5.636,4.431 5.219,5.024 5.000,5.700 C5.021,6.968 5.029,8.067 4.400,9.100 C4.251,9.345 3.884,9.678 3.700,9.900 L12.300,9.900 C12.156,9.741 11.723,9.275 11.600,9.100 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M5.800,13.100 C5.674,12.871 5.715,12.585 5.900,12.400 C6.085,12.215 6.371,12.174 6.600,12.300 C6.772,12.498 6.974,12.667 7.200,12.800 C7.447,12.946 7.717,13.048 8.000,13.100 C8.283,13.048 8.553,12.946 8.800,12.800 C9.026,12.667 9.228,12.498 9.400,12.300 C9.629,12.174 9.915,12.215 10.100,12.400 C10.285,12.585 10.326,12.871 10.200,13.100 C9.978,13.382 9.708,13.619 9.400,13.800 C8.976,14.050 8.495,14.186 8.000,14.200 C7.505,14.186 7.024,14.050 6.600,13.800 C6.292,13.619 6.022,13.382 5.800,13.100 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logNotice",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
