<template>
  <svg :copyright="this.$root.Copyright" :style="custom"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16px" height="16px">
    <path fill-rule="evenodd"  fill="rgb(236, 48, 39)"
          d="M8.000,-0.000 C12.418,-0.000 16.000,3.582 16.000,8.000 C16.000,12.418 12.418,16.000 8.000,16.000 C3.582,16.000 -0.000,12.418 -0.000,8.000 C-0.000,3.582 3.582,-0.000 8.000,-0.000 Z"/>
    <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
          d="M3.200,11.200 C2.761,11.639 2.761,12.361 3.200,12.800 C3.639,13.239 4.361,13.239 4.800,12.800 C5.867,11.467 6.933,10.133 8.000,8.800 L11.200,12.800 C11.639,13.239 12.361,13.239 12.800,12.800 C13.239,12.361 13.239,11.639 12.800,11.200 C11.467,10.133 10.133,9.067 8.800,8.000 L12.800,4.800 C13.239,4.361 13.239,3.639 12.800,3.200 C12.361,2.761 11.639,2.761 11.200,3.200 C10.133,4.533 9.067,5.867 8.000,7.200 L4.800,3.200 C4.361,2.761 3.639,2.761 3.200,3.200 C2.761,3.639 2.761,4.361 3.200,4.800 C4.533,5.867 5.867,6.933 7.200,8.000 L3.200,11.200 Z"/>
  </svg>
</template>

<script>
export default {
  name: "logError",
  props:{
    custom:{
      type:String,
      default:null,
      required:false,
    }
  }
}
</script>

<style scoped>

</style>
