<template>
    <div class="BananaCardItemA" :style="BananaCardItemStyleA">
        <div class="cardItemA" @touchstart.stop="touchDetailsStart($event)" @touchend.stop="touchDetailsEnd($event)" @click.stop="touchOpen()">
            <div class="left" >
                <img :src="url" alt="" class="headImg" :style="delay"/>
            </div>
            <div class="right" @mousemove.stop="hoverDetails($event)" @mouseleave.stop="view=false">
                <div class="row1">
                    {{group_nickname}}
                </div>
                <div class="row2">
                    QQ:{{qq_id}}&nbsp;&nbsp;UID:{{game_id}}
                </div>
                <div class="row2">
                    Join:{{insert_time}}
                </div>
            </div>
        </div>
    </div>
    <div class="hoverDetailsA" ref="hoverDetailsA" :style="hoverDetailsStyleA" v-show="view">
        <div class="row8">
            群内昵称：{{group_nickname}}
        </div>
        <div class="row8">
            游戏昵称：{{game_nickname}}
        </div>
        <div class="row8">
            惯用昵称：{{habit_name}}
        </div>
        <div class="row8">
            ＱＱ账号：{{qq_id}}
        </div>
        <div class="row8">
            游戏账号：{{game_id}}
        </div>
        <div class="row8">
            加入时间：{{insert_time}}
        </div>
        <div class="row8">
            备注信息：{{remarks}}
        </div>
    </div>
    <div class="hoverDetailsCloseB" v-if="this.viewB===true" @touchend.stop="touchClose()" @click.stop="touchClose()"/>
    <div class="hoverDetailsB" v-show="viewB">
        <div class="row8">
            群内昵称：{{group_nickname}}
        </div>
        <div class="row8">
            游戏昵称：{{game_nickname}}
        </div>
        <div class="row8">
            惯用昵称：{{habit_name}}
        </div>
        <div class="row8">
            ＱＱ账号：{{qq_id}}
        </div>
        <div class="row8">
            游戏账号：{{game_id}}
        </div>
        <div class="row8">
            加入时间：{{insert_time}}
        </div>
        <div class="row8">
            备注信息：{{remarks}}
        </div>
    </div>
</template>

<script>
export default {
    name: "BananaCardItem",
    props:{
        width:{
            type:Number,
            default:320,
            required:false
        },
        insert_time:{
            type:String,
            default:'none',
            required:true
        },
        serial_number:{
            type:Number,
            default:0,
            required:false
        },
        qq_id:{
            type:String,
            default:'none',
            required:true
        },
        group_nickname:{
            type:String,
            default:'none',
            required:true
        },
        game_id:{
            type:String,
            default:'none',
            required:true
        },
        game_nickname:{
            type:String,
            default:'none',
            required:true
        },
        habit_name:{
            type:String,
            default:'none',
            required:true
        },
        remarks:{
            type:String,
            default:'none',
            required:false
        },
    },
    data(){
        return{
            left: 0,
            top:0,
            view:false,
            viewB:false,
            touchStartX:null,
            touchStartY:null,
        }
    },
    computed:{
        vertical(){
            return this.$store.state.pageConfig.vertical;
        },
        url(){
            return `http://q.qlogo.cn/g?b=qq&nk=${this.qq_id}&s=640&mType=friendlist`;
        },
        delay(){
            return `animation-delay: ${this.serial_number*0.02}s;`;
        },
        hoverDetailsStyleA(){
            return {
                opacity:this.view?'1':'0',
                transform:`translate(${this.left}px, ${this.top}px)`
            }
        },
        BananaCardItemStyleA(){
            if(this.$refs.hoverDetailsA){
                let rec=this.$refs.hoverDetailsA.getBoundingClientRect();
                if(rec.height+rec.top>=window.innerHeight){
                    let top=window.innerHeight-rec.height;
                    return {
                        transform:`translate(${this.left}px, ${top}-px)`
                    }
                }
            }
            return{
                width: this.width+'px',
                animationDelay: `${this.serial_number*0.02}s`,
            }
        },
    },
    methods:{
        touchDetailsStart(ev) {
            this.touchStartX = ev.targetTouches[0].clientX;
            this.touchStartY = ev.targetTouches[0].clientY;
        },
        touchDetailsEnd(ev) {
            let X = ev.changedTouches[0].clientX;
            let Y = ev.changedTouches[0].clientY;
            let tolerance = 2; // 容差范围±2px
            if (Math.abs(X - this.touchStartX) <= tolerance && Math.abs(Y - this.touchStartY) <= tolerance) {
                this.touchOpen();
            }
        },
        touchOpen(){
            setTimeout(()=>this.viewB=true,2);
        },
        touchClose(){
            setTimeout(()=>this.viewB=false,2);
        },
        hoverDetails(ev){//鼠标悬浮显示详细信息
            if(this.vertical)return false;
            let top=null;
            if(this.$refs.hoverDetailsA){
                let rec=this.$refs.hoverDetailsA.getBoundingClientRect();
                if(rec.height+rec.top>=window.innerHeight){
                    top=window.innerHeight-rec.height;
                }
            }
            this.left=ev.x+10;
            this.top=top===null?ev.y+10:top;
            if(this.view===false)this.view=true;
        }
    }
}
</script>

<style scoped>
/*AB css start*/
/*A横屏*/
.hoverDetailsA{
    width: calc(225px - 20px);
    height: auto;
    padding: 10px;
    background: rgba(250,250,255,0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    position: fixed;
    pointer-events: none;
    z-index: 60;
    border-radius: 10px;
    color: #1b1b1b;
    left: 0;
    top: 0;
    opacity: 0;
}
.hoverDetailsCloseB{
    width: calc(100% - 42px);
    height: 100%;
    position: fixed;
    z-index: 60;
    left: 42px;
    top: 0;
    animation:toBlur 0.5s forwards;
}
@keyframes toBlur{
    from{
        backdrop-filter: blur(0px);
    }
    to{
        backdrop-filter: blur(2px);
    }
}
.hoverDetailsB{
    width: calc(250px - 20px);
    height: auto;
    padding: 10px;
    background: rgba(250,250,255,0.9);
    box-shadow: 2px 2px 6px rgba(0,0,0,0.2);
    position: fixed;
    z-index: 65;
    border-radius: 10px;
    color: #1b1b1b;
    left: calc(50% - 105px);
    top: calc(50% - 150px);
    opacity: 1;
}
.BananaCardItemA{
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: BananaCardItemAnimationA 0.3s forwards;
    opacity: 0;
    margin: 8px 0px;
}
@keyframes BananaCardItemAnimationA {
    0%{
        opacity: 0;
        transform: scale(2);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}
.cardItemA{
    width: calc(320px - 10px);
    height: calc(80px - 10px);
    padding: 5px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.19);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/*AB css end*/
/*通用css start*/
.left{
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.right{
    cursor: pointer;
    width: calc(100% - 60px - 5px);
    padding-left: 5px;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.headImg{
    width: 90%;
    height: auto;
    border-radius: 5px;
    animation:headImgAnimation 0.5s forwards;
}
@keyframes headImgAnimation{
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
.row1{
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
}
.row2{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
}
.row8{
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
}
/*通用css end*/
</style>